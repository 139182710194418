/* react */
import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

/* redux and reducer */
import { useDispatch, useSelector } from 'react-redux';

/* css, image */
import '../../assets/scss/themes.scss';

/* component */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeftSidebarCLientArea from '../../Common/Components/LeftSidebarClientArea/LeftSidebarClientArea';
import DetailChat from './DetailChat/DetailChat';
import Tabs from './Tabs/Tabs';

/* import socket listner */
import { Col, Row } from 'reactstrap';
import {
  changeIsAnnouncement,
  changeStatusAlertRunned,
  changeVolumeAlert,
  layoutSetupSelector,
} from '../../app/Layouts/LayoutSlice';
import ModalMd from '../../Common/Components/Modal/ModalMd';
import {
  checkAvailableSessions,
  isEmptyObj,
  validateSessions,
} from '../../Common/utils/helpers';
import {
  getDetailClientChat,
  getDetailHistoryChat,
  getHistoryChatAction,
} from './DetailChat/DetailChatClientSlice';

import useSound from 'use-sound';
import swushSfx from '../../assets/sound/swush.mp3';
import Service from '../../Common/service';
import { getListResolveChat } from './Tabs/ChatWithClients/ListChat/ListChatClientSlice';
import { authSelector } from '../../app/Auth/AuthSlice';

/* component */
const SetupModal = (params) => {
  const { user, isAnnouncement, handlerClosed, handlerFunc } = params;

  const emptyUserData = isEmptyObj(user);

  if (emptyUserData) {
    return <></>;
  }

  if (user.roles_id === 4) {
    return (
      <ModalMd
        isOpen={isAnnouncement}
        handlerClosed={handlerClosed}
        handlerFunc={handlerFunc}
        title="Announcement"
        headerModal={true}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        footerModal={false}
        backdrop="static"
      >
        <div className="d-flex justify-content-center align-items-center">
          <Row>
            <Col sm="12" md="12" lg="12">
              When you close this pop up, sound notification automatically
              enabled
            </Col>
          </Row>
        </div>
      </ModalMd>
    );
  } else {
    return <></>;
  }
};

const Client = (props) => {
  const { chatIdActive } = useParams();
  const dispatch = useDispatch();
  const { isAnnouncement, runAlert, volumeAlert } =
    useSelector(layoutSetupSelector);
  const { user } = useSelector(authSelector);

  const [play] = useSound(swushSfx, {
    volume: volumeAlert,
  });

  /* handler */
  const handlerShowAnnouncement = () => {
    const status = isAnnouncement ? false : true;
    dispatch(changeIsAnnouncement(status));
  };

  const handlerOnCloseAnnouncement = () => {
    dispatch(changeVolumeAlert(0));
    dispatch(changeStatusAlertRunned(true));
  };

  const handlerRunAlert = () => {
    play();
    dispatch(changeStatusAlertRunned(false));
  };

  /* onload first */
  useEffect(() => {
    const checkSession = checkAvailableSessions();
    if (!checkSession.isSessions) {
      localStorage.clear();
      const urlRedirect = `${process.env.REACT_APP_LIVE_ENDPOINT_V1}/login`;
      window.location.replace(urlRedirect);
    } else {
      validateSessions(checkSession.dataSessions, 'update_session')
        .then(async (response) => {
          const { data } = response;

          const usersData = {
            agent_id: data.id,
            uuid: data.uuid,
            company_uuid: data.company_uuid,
            name_agent: data.name,
            email_agent: data.email,
            phone_agent: data.phone,
            avatar: data.avatar,
            id_department: data.id_department,
            department_name: data.department_name,
            company_name: data.company_name,
            company_id: data.id_company,
            roles_id: data.permission,
            permission_name: data.roles_name,
            token: data.token,
            type_user: 'agent',
            status: 'online',
            full_access: data.full_access,
          };
          await Service.authUserToSocket(usersData);
          dispatch(getListResolveChat(data.uuid));

          if (Boolean(chatIdActive)) {
            const splitParams = chatIdActive.split('-');
            if (splitParams[1] === 'CH') {
              dispatch(getHistoryChatAction(splitParams[0]));
              dispatch(getDetailHistoryChat(splitParams[0]));
            } else {
              dispatch(getDetailClientChat(splitParams[0]));
            }
          }
        })
        .catch((err) => {
          localStorage.clear();
          const urlRedirect = `${process.env.REACT_APP_LIVE_ENDPOINT_V1}/login`;
          window.location.replace(urlRedirect);
        });
    }
  }, []);

  useEffect(() => {
    document.title = `${props.title} | ${process.env.REACT_APP_NAME}`;
  }, []);

  useEffect(() => {
    if(runAlert){
      handlerRunAlert();
    }
  }, [runAlert]);

  return (
    <>
      <ToastContainer limit={5} />
      <div className="layout-wrapper d-lg-flex">
        <LeftSidebarCLientArea />
        <Tabs />
        <DetailChat chatId={chatIdActive} />
      </div>
      <SetupModal
        user={user}
        isAnnouncement={isAnnouncement}
        handlerClosed={handlerOnCloseAnnouncement}
        handlerFunc={handlerShowAnnouncement}
      />
    </>
  );
};

export default Client;
