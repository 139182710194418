import { useSelector } from 'react-redux';
import { updateIsLogout } from '../../../app/Auth/AuthSlice';
import {
  changeStatusAlertRunned,
  changeVolumeAlert,
} from '../../../app/Layouts/LayoutSlice';
import { store } from '../../../app/store';
import {
  addMessage,
  resolveChat,
  updateBeforeJoinRoom,
  updateLabelChoose,
  changeTypeConversation,
  getChatDetailClient
} from '../../../features/Client/DetailChat/DetailChatClientSlice';
import {
  getListResolveChat,
  updateCountingOngoing,
  updateOngoingList,
  updatePendingList,
  updateTransferList,
} from '../../../features/Client/Tabs/ChatWithClients/ListChat/ListChatClientSlice';
import {
  updateListAgents,
  updateListDepartments,
} from '../../../features/Client/Tabs/ChatWithClients/OnlineUsers/OnlineUsersSlice';
import { notify } from '../../utils/helpers';
import { handlerResetChat } from './ClientActions';

/* update agent online from listener socket */
export const updateListAgentOnline = (data) => {
  store.dispatch(updateListAgents(data));
};

/* update department agent online from listener socket */
export const updateListDepartmentOnline = (data) => {
  store.dispatch(updateListDepartments(data));
};

/* update list pendig */
export const updateDataListPending = (data) => {
  const state = store.getState();
  const session = state.authSetup.user;
  const messageVirtual = localStorage.getItem('messageVirtual');

  if (session.roles_id === 4) {
    const dataGeneral = state.listChatClientSetup.pending.filter(item => item.type === 'general');
    const newListPending = data.length;
    const lengthOriginListPending = dataGeneral.length;
    if (newListPending > lengthOriginListPending && data[0].type === 'general') {
      store.dispatch(changeVolumeAlert(1));
      store.dispatch(changeStatusAlertRunned(true));
    }
    store.dispatch(updatePendingList(data));
    if(data.length > 0 && data[0].chat_id === state.detailChatCLientSetup.chatId && data[0].message !== messageVirtual){
      store.dispatch(addMessage(data[0]));
      localStorage.setItem('messageVirtual', data[0].message);
      store.dispatch(changeTypeConversation(data[0].type));

      if(data[0].type === 'general'){
        store.dispatch(getChatDetailClient(data[0].chat_id));
      }
    }
  }
};

/* update list ongoing */
export const updateDataListOngoing = (data) => {
  store.dispatch(
    updateCountingOngoing({
      unread_bubble_chat: data.unread_bubble_count,
      unread_count: data.unread_count,
    }),
  );
  store.dispatch(updateOngoingList(data.list));
};

/* update list transfer */
export const updateListTransfer = (data) => {
  const state = store.getState();
  const newListTransfer = data.length;
  const originListTransferLength = state.listChatClientSetup.transfer.length;

  const session = state.authSetup.user;

  if (session.roles_id === 4) {
    if (newListTransfer > originListTransferLength) {
      store.dispatch(changeVolumeAlert(1));
      store.dispatch(changeStatusAlertRunned(true));
    }
    store.dispatch(updateTransferList(data));
  }
};

/* update list bubble chat */
export const updateListBubbleChat = (data) => {
  const session = store.getState().authSetup.user;
  if (!data.success) {
    return notify('error', 4000, 'Error from system, please back to home!');
  } else {
    const stateList = store.getState();
    const chatId = stateList.detailChatCLientSetup.chatId;
    if(data.is_sender == true){
      store.dispatch(changeVolumeAlert(0));
      store.dispatch(changeStatusAlertRunned(true)); 
    }else if(chatId === data.chat_id && data.from === stateList.authSetup.user.agent_id && data.is_sender == false){
      store.dispatch(addMessage(data));
      store.dispatch(changeVolumeAlert(0));
      store.dispatch(changeStatusAlertRunned(true));
    }else if(chatId === data.chat_id && data.from != stateList.authSetup.user.agent_id && data.is_sender == false){
      store.dispatch(addMessage(data));
      store.dispatch(changeVolumeAlert(1));
      store.dispatch(changeStatusAlertRunned(true));     
    }else if(chatId != data.chat_id && data.from === stateList.authSetup.user.agent_id){
      store.dispatch(changeVolumeAlert(1));
      store.dispatch(changeStatusAlertRunned(true)); 
    }else if(chatId != data.chat_id && data.from != stateList.authSetup.user.agent_id){
      store.dispatch(changeVolumeAlert(1));
      store.dispatch(changeStatusAlertRunned(true)); 
    }else{
      store.dispatch(changeVolumeAlert(0));
      store.dispatch(changeStatusAlertRunned(true));
    }
  }
};

/* alert transfer chat */
export const alertTransferChat = (response) => {
  if (response.success === true) {
    return notify('success', 3000, 'Transfer Chat Success!');
  } else {
    return notify('error', 4000, response.message);
  }
};

/* update State before chat solved */
export const beforeSolveChat = (response, event) => {
  const session = store.getState().authSetup.user;
  if (response.success) {
    store.dispatch(resolveChat());
    store.dispatch(getListResolveChat(session.uuid));
    return notify('success', 4000, response.message);
  } else {
    return notify('error', 4000, 'something wrong!');
  }
};

export const updateDataLabel = (response) => {
  store.dispatch(updateLabelChoose(response.data));
  return notify('success', 3000, 'Label is updated!');
};

export const handlerUpdateJoinRoom = (response) => {
  const { data, success } = response;

  if (!success) {
    store.dispatch(updateBeforeJoinRoom(data));
    console.warn('error joined room!');
  } else {
    store.dispatch(updateBeforeJoinRoom(data));
  }
};

export const logoutResponse = (response) => {
  const state = store.getState();
  const user = state.authSetup.user;
  if (user.uuid === response.data.uuid) {
    localStorage.clear();
    store.dispatch(updateIsLogout());
    return notify('success', 3000, 'Agent logout on all device');
  }
};
