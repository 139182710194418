import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { authSelector } from '../../../app/Auth/AuthSlice';
import AvatarDefault from '../../../assets/images/users/avatar/avatar-4.png';
import { detailChatClientSelector, updateIsOpen } from '../../../features/Client/DetailChat/DetailChatClientSlice';
import {
  capitalizeFirstLetter,
  capitalizeFirstMultiParagraph,
} from '../../utils/helpers';
import { handlerUpdateLabels } from '../../WebSocket/Clients/ClientActions';
import CardList from '../CardList/CardList';
import CustomCollapse from '../CustomCollapse/CustomCollapse';
import LabelDetailClient from '../Labels/LabelDetailClient';
import LabelSelector from '../Labels/LabelSelector';
import { getDataLabels, labelSelector } from '../Labels/LabelSlice';
import ModalMd from '../Modal/ModalMd';
import {
  updateCollapse,
  updateFormModalLabel,
  UserProfileSidebarSelector,
} from './UserProfileSidebarSlice';
import SkeletonListChat from '../Skeletons/SkeletonListChat';
import ClientListChat from '../ListChat/ClientListChat';
import { addChatId, reportChatSelector, getRelatedChat, changeStatusModal } from '../../../features/ReportChat/ReportChatSlice';
import ModalFullScreen from '../Modal/ModalFullScreen';
import LoaderSpinner from '../Loader/LoaderSpinner';
import BubbleChatClient from '../BubbleChat/BubbleChatClient';
import { useNavigate } from 'react-router-dom';
import { layoutSetupSelector } from '../../../app/Layouts/LayoutSlice';

function ProfileClientChat(props) {
  const { detailClient, rightBarMenu, updateStatusRightBar } = props;
  let userName = '';
  if (Boolean(detailClient.user_name)) {
    userName = capitalizeFirstMultiParagraph(detailClient.user_name);
  }

  /* state & selector*/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refBubbleReportChat = useRef();
  const [newLabels, setNewLabels] = useState([]);
  const { user } = useSelector(authSelector);
  const { listLabels } = useSelector(labelSelector);
  const { labelChoosed, actionHistory } = useSelector(detailChatClientSelector);
  const {
    formModalLabel,
    isAboutCollapse,
    isLabelCollapse,
    isChatActionCollapse,
    isRelatedChatCollapse,
  } = useSelector(UserProfileSidebarSelector);
  const {
    chatId, 
    loaderRelated, 
    dataChatRelated, 
    dataDetailReportChat, 
    isModal, 
    dataChatReplies,
    loaderDetail
  } = useSelector(reportChatSelector)
  const {activeTab} = useSelector(layoutSetupSelector)
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(true);

  /* use effect */
  useEffect(() => {
    dispatch(getDataLabels(user.token));
  }, []);

  /* handler */
  const handlerCollapseAbout = () => {
    dispatch(updateCollapse({ name: 'about', status: isAboutCollapse }));
  };

  const handlerCollapseAbout2 = () => {
    setOpenCollapse(!openCollapse);
  };

  const handlerCollapseLabel = () => {
    dispatch(updateCollapse({ name: 'label', status: isLabelCollapse }));
  };

  const handlerCollapseChatAction = () => {
    dispatch(
      updateCollapse({ name: 'chat_action', status: isChatActionCollapse })
    );
  };

  const handlerCollapseRelated = () => {
    dispatch(
      updateCollapse({ name: 'related', status: isRelatedChatCollapse })
    );
    let noTelp;
    if(detailClient.channel_name === "Whatsapp" && activeTab === 'Chat-w-clients'){
      noTelp = detailClient.user_phone.replace(/\+/g, '') + '@c.us';
    }else{
      noTelp = detailClient.user_phone
    }
    const data = {
      with_pagination: true,
      company_uuid: user.uuid,
      set_per_page: 30,
      page: 1,
      noTelp: noTelp,
      channel_id: detailClient.channel_id
    }
    dispatch(getRelatedChat(data));
    dispatch(addChatId(detailClient.chatId));
  }

  const handlerCollapseRelated2 = () => {
    setOpenCollapse2(!openCollapse2);
  };

  const handlerModalReportChat = () => {
    const isStatus = !isModal ? true : false;
    dispatch(changeStatusModal(isStatus));
    dispatch(addChatId(''));
    dispatch(updateIsOpen(detailClient.chat_id))
    if(activeTab === 'Chat-w-clients'){
      navigate(`../chat-with-client/${detailClient.chat_id}-CO`);
    }else{
      navigate(`../chat-with-client/${detailClient.chat_id}-CH`);
    }
  };

  const handlerChangeStatusRightBar = () => {
    dispatch(updateStatusRightBar(false));
    dispatch(
      updateCollapse({ name: 'related', status: true })
    );
  };

  const handlerShowHideModalLabel = () => {
    const status = !formModalLabel ? true : false;
    if (!status) {
      setNewLabels([]);
    }
    dispatch(updateFormModalLabel(status));
  };

  const handlerSaveLabels = () => {
    const labels = [];

    if (newLabels.length > 0) {
      newLabels.map((value, key) => {
        let data = {
          name: value.label,
          color: value.color,
          id: value.value,
        };

        return labels.push(data);
      });
    }

    handlerUpdateLabels(detailClient.chat_id, labels);
    handlerShowHideModalLabel();
  };

  const handlerChooseLabel = (val) => {
    setNewLabels(val);
  };

  const handlerRemoveLabel = (val) => {
    const result = [];
    labelChoosed.filter((value) => {
      if (value.id !== val) {
        return result.push(value);
      }
    });

    handlerUpdateLabels(detailClient.chat_id, result);
  };

  const Items = (params) => {
    const { isLoader, data } = params;
    if (isLoader) {
      return <SkeletonListChat />;
    }

    if (data.length < 1) {
      return (
        <li id="conversation-chat-empty" className="active">
          <div className="d-flex justify-content-center p-5">
            <h5 className="mb-2 font-size-14">Chat Not Found!</h5>
          </div>
        </li>
      );
    }

    return data.map((val, index) => {
      let isActive = '';
      if (chatId === val.chat_id) {
        isActive = 'active';
      }
      

      return (
        <ClientListChat
          key={index}
          index={index}
          data={val}
          isActive={`listChatClient ${isActive}`}
          chatIdActive={chatId}
          detailType="CP"
          relatedChat={true}
        />
      );
    });
  };

  const CompListActions = (params) => {
    const { data } = params;
    if (data) {
      return data.map((value, key) => {
        let colorBadge = '';
        let textBadge = '';

        const headerTitle = !value.action_name
          ? '-'
          : capitalizeFirstMultiParagraph(value.action_name.replace(/_/g, ' '));

        if (
          ['canceled_by_user', 'canceled_by_system'].includes(value.action_name)
        ) {
          colorBadge = 'danger';
          textBadge = 'white';
        } else if (['resolved'].includes(value.action_name)) {
          colorBadge = 'success';
          textBadge = 'white';
        } else if (
          ['assign_agent', 'first_response_sent'].includes(value.action_name)
        ) {
          colorBadge = 'tangerin';
          textBadge = 'white';
        } else {
          colorBadge = 'secondary';
          textBadge = 'white';
        }

        return (
          <Card className="p-2 border mb-2" key={key}>
            <div className="align-items-center">
              <div className="overflow-hidden">
                <CardList
                  classHeaderTitle="text-start font-size-16 mb-1"
                  withBadge
                  withBadgePill
                  badgeTextColor={textBadge}
                  headerTitle={headerTitle}
                  colorBadge={colorBadge}
                  withSmallContent
                  smallContent={value.formatted_date}
                  classSmallContent="text-end font-size-12 text-muted"
                >
                  <p className="text-muted font-size-12 mb-0">
                    {value.description}
                  </p>
                </CardList>
              </div>
            </div>
          </Card>
        );
      });
    }
  };

  return (
    <>
      <div
        style={{ display: 'block' }}
        className={classNames({
          'user-profile-sidebar': true,
          'profile-sidebar-full': rightBarMenu,
        })}
      >
        <div className="px-3 px-lg-4 pt-3 pt-lg-4">
          <div className="user-chat-nav text-end">
            <Button
              color="none"
              type="button"
              className="nav-btn"
              id="user-profile-hide"
              onClick={handlerChangeStatusRightBar}
            >
              <i className="ri-close-line"></i>
            </Button>
          </div>
        </div>
        <div className="text-center p-4 border-bottom">
          <div className="mb-4 d-flex justify-content-center">
            <img
              src={AvatarDefault}
              className="rounded-circle avatar-lg img-thumbnail"
              alt="avatar"
            />
          </div>
          <h5 className="font-size-16 mb-1 text-truncate">{userName}</h5>
        </div>
        <SimpleBar
          style={{ maxHeight: '100%' }}
          className="p-4 user-profile-desc"
        >
          <Card className="mb-1 shadow-done border">
            <CustomCollapse
              title="About"
              iconClass="ri-user-line"
              isOpen={isAboutCollapse}
              toggleCollapse={handlerCollapseAbout}
            >
              <div>
                <p className="text-muted mb-1">Chat ID</p>
                <h5 className="font-size-14">{detailClient.chat_id}</h5>
              </div>
              <div className="mt-3">
                <p className="text-muted mb-1">User Name</p>
                <h5 className="font-size-14">{userName}</h5>
              </div>
              <div className="mt-3">
                <p className="text-muted mb-1">Email</p>
                <h5 className="font-size-14">{detailClient.user_email}</h5>
              </div>
              <div className="mt-3">
                <p className="text-muted mb-1">User Phone</p>
                <h5 className="font-size-14">{detailClient.user_phone ? detailClient.user_phone : detailClient.no_whatsapp}</h5>
              </div>
              <div className="mt-3">
                <p className="text-muted mb-1">Department</p>
                <h5 className="font-size-14 mb-0">
                  {Boolean(detailClient.department_name)
                    ? capitalizeFirstLetter(detailClient.department_name)
                    : '-'}
                </h5>
              </div>
              <div className="mt-3">
                <p className="text-muted mb-1">Channel From</p>
                <h5 className="font-size-14 mb-0">
                  {Boolean(detailClient.channel_name)
                    ? capitalizeFirstLetter(detailClient.channel_name)
                    : '-'}
                </h5>
              </div>
              <div className="mt-3">
                <p className="text-muted mb-1">First Topic</p>
                <h5 className="font-size-14 mb-0">
                  {Boolean(detailClient.topic_name)
                    ? capitalizeFirstLetter(detailClient.topic_name)
                    : '-'}
                </h5>
              </div>
            </CustomCollapse>
          </Card>
          <Card className="mb-1 shadow-done border">
            <CustomCollapse
              title="Labels"
              iconClass="ri-price-tag-3-line"
              isOpen={isLabelCollapse}
              toggleCollapse={handlerCollapseLabel}
            >
              <Row>
                {![9, 10, 11].includes(detailClient.status) && (
                  <Col xs="12" md="12" lg="12" xl="12" className="mb-2">
                    <div className="float-end">
                      {parseInt(detailClient.agent_id) ===
                        parseInt(user.agent_id) && (
                        <Button
                          color="tangerin"
                          className="btn-sm"
                          onClick={handlerShowHideModalLabel}
                        >
                          Add
                          <span className="ms-2">
                            <i className="ri-add-fill"></i>
                          </span>
                        </Button>
                      )}
                    </div>
                  </Col>
                )}
                <LabelDetailClient
                  detailClient={detailClient}
                  labelChoosed={labelChoosed}
                  removeLabel={(id) => handlerRemoveLabel(id)}
                />
              </Row>
            </CustomCollapse>
          </Card>
          {[9, 10, 11].includes(detailClient.status) && (
            <Card className="mb-1 shadow-done border">
              <CustomCollapse
                title="List Chat Actions"
                iconClass="ri-chat-history-line"
                isOpen={isChatActionCollapse}
                toggleCollapse={handlerCollapseChatAction}
              >
                <CompListActions data={actionHistory} />
              </CustomCollapse>
            </Card>
          )}
          <Card className="mb-1 shadow-done border">
            <CustomCollapse
              title="Related Chat"
              iconClass="ri-chat-history-line"
              isOpen={isRelatedChatCollapse}
              toggleCollapse={handlerCollapseRelated}
              className={true}
              style={{overflow: 'hidden scroll', maxHeight: '400px'}}
            >
              <ul
                className="list-unstyled chat-list chat-user-list"
                id="chat-list"
              >
                <Items isLoader={loaderRelated} data={dataChatRelated} />
              </ul>
            </CustomCollapse>
          </Card>
        </SimpleBar>
      </div>
      <ModalMd
        isOpen={formModalLabel}
        handlerFunc={handlerShowHideModalLabel}
        title="Form Label"
        headerModal={true}
        unmountOnClose={true}
        keyboard={false}
        centered={true}
        footerModal={
          <Button
            className="btn-sm"
            color="tangerin"
            onClick={handlerSaveLabels}
          >
            Save{' '}
            <span className="ms-2">
              <i className="ri-save-2-fill"></i>
            </span>
          </Button>
        }
        backdrop="static"
      >
        <LabelSelector
          listLabels={listLabels}
          labelChoosed={labelChoosed}
          updateLabel={(val) => handlerChooseLabel(val)}
        />
      </ModalMd>
      <ModalFullScreen
        isOpen={isModal}
        handlerFunc={handlerModalReportChat}
        title="Detail Related Chat"
        headerModal={true}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        backdrop="static"
      >
        <div className="row">
          <div className="col-8">
            <SimpleBar
              ref={refBubbleReportChat}
              className="report-detail-chat-conversation px-3"
              id="messages"
            >
              {loaderDetail ? <LoaderSpinner /> : (
                <ul className="list-unstyled mb-0">
                  {dataChatReplies.map((val, index) => {
                    return <BubbleChatClient key={index} data={val} />;
                  })}
                </ul>
              )}
            </SimpleBar>
          </div>
          <div className="col-4 border-start border-5">
            <SimpleBar className="report-detail-chat-conversation px-3">
              <Card className="mb-1 shadow-done border">
                <CustomCollapse
                  title="About"
                  iconClass="ri-user-line"
                  isOpen={openCollapse}
                  toggleCollapse={handlerCollapseAbout2}
                >
                  <div>
                    <p className="text-muted mb-1">Chat ID</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.chat_id
                        ? '-'
                        : dataDetailReportChat.chat_id}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Username</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_name
                        ? '-'
                        : dataDetailReportChat.user_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Email</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_email
                        ? '-'
                        : dataDetailReportChat.user_email}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Phone</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_phone
                        ? '-'
                        : dataDetailReportChat.user_phone}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">First Chat</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.message
                        ? '-'
                        : dataDetailReportChat.message}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Url</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_site_url
                        ? '-'
                        : dataDetailReportChat.user_site_url}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client IP</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_ip
                        ? '-'
                        : dataDetailReportChat.user_ip}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Browser Access</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_client_agent
                        ? '-'
                        : dataDetailReportChat.user_client_agent}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Channel Name</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.channel_name
                        ? '-'
                        : dataDetailReportChat.channel_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Department Name</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.department_name
                        ? '-'
                        : dataDetailReportChat.department_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Topic</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.topic_name
                        ? '-'
                        : dataDetailReportChat.topic_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Rate From Client</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.rating
                        ? '-'
                        : dataDetailReportChat.rating}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">First Chat Time</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.formatted_date
                        ? '-'
                        : dataDetailReportChat.formatted_date}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Agent Name</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.formatted_date
                        ? '-'
                        : dataDetailReportChat.formatted_date}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Agent Email</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.formatted_date
                        ? '-'
                        : dataDetailReportChat.formatted_date}
                    </h5>
                  </div>                  
                </CustomCollapse>
              </Card>
              <Card className="mb-1 shadow-done border">
                <CustomCollapse
                  title="Related Chat"
                  iconClass="ri-chat-history-line"
                  isOpen={openCollapse2}
                  style={{overflow: 'hidden scroll', maxHeight: '550px'}}
                  className={true}
                  toggleCollapse={handlerCollapseRelated2}
                >
                  <ul
                    className="list-unstyled chat-list chat-user-list"
                    id="chat-list"
                  >
                    <Items isLoader={loaderRelated} data={dataChatRelated} />
                  </ul>
                </CustomCollapse>
              </Card>
            </SimpleBar>
          </div>
        </div>        
      </ModalFullScreen>
    </>
  );
}

export default ProfileClientChat;
