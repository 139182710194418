import classnames from 'classnames';
import React, { Fragment } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { listChatClientSelector } from '../ListChat/ListChatClientSlice';

function TabCategoryChat(props) {
  /* configuration */
  const { activeMenu, setCategoryChat } = props;
  const { pending, transfer, ongoing_unread_count } = useSelector(
    listChatClientSelector
  );
  const pendingGeneral = pending.filter(item => item.type === 'general');
  const lengthPending = pendingGeneral.length;
  const lengthTransfer = transfer.length;
  /* handler */
  const changeActiveCategoryChat = (val) => {
    setCategoryChat(val);
  };

  return (
    <Fragment>
      <Nav pills className="mb-3">
        <NavItem id="chat-pending" className="font-size-14">
          <NavLink
            className={classnames({
              active: activeMenu === 'chat-pending',
            })}
            onClick={() => {
              changeActiveCategoryChat('chat-pending');
            }}
          >
            Pending{' '}
            {lengthPending > 0 && (
              <Badge pill bg="color-tangerin-600">
                {lengthPending}
              </Badge>
            )}
          </NavLink>
        </NavItem>
        <NavItem id="chat-transfer" className="font-size-14">
          <NavLink
            className={classnames({
              active: activeMenu === 'chat-transfer',
            })}
            onClick={() => {
              changeActiveCategoryChat('chat-transfer');
            }}
          >
            Transfer{' '}
            {lengthTransfer > 0 && (
              <Badge pill bg="color-tangerin-600">
                {lengthTransfer}
              </Badge>
            )}
          </NavLink>
        </NavItem>
        <NavItem id="chat-ongoing" className="font-size-14">
          <NavLink
            className={classnames({
              active: activeMenu === 'chat-ongoing',
            })}
            onClick={() => {
              changeActiveCategoryChat('chat-ongoing');
            }}
          >
            On Going{' '}
            {ongoing_unread_count > 0 && (
              <Badge pill bg="color-gray-500">
                {ongoing_unread_count}
              </Badge>
            )}
          </NavLink>
        </NavItem>
      </Nav>
    </Fragment>
  );
}

export default TabCategoryChat;
