import React from 'react';
import { Input, InputGroup } from 'reactstrap';
import _ from 'lodash';
import { useCallback } from 'react';
import styles from './ClientHeaderTab.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { searchHistory, updateSearchStatus } from '../../../features/Client/Tabs/ChatWithClients/ListChat/ListChatClientSlice';
import { authSelector } from '../../../app/Auth/AuthSlice';

function ClientHeaderTab(props) {
  const { attrClass, value, isFieldSearch } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);

  /* handler */
  const handlerSearchHistory = (event) => {
    const search = event.target.value;
    const now = new Date();
    const nowDate = now.toISOString().substring(0, 10);
    const threedaysago = new Date(now.getTime() - (3 * 24 * 60 * 60 * 1000));
    const threedaysagoDate = threedaysago.toISOString().substring(0, 10);
    if(search === ''){
      dispatch(updateSearchStatus(false))
    }else{
      dispatch(searchHistory({search: search, uuid: user.uuid, start_date:threedaysagoDate, end_date: nowDate}));
    }
  };

  const querySearch = useCallback(
    _.debounce((event) => handlerSearchHistory(event), 500),
    []
  );

  const renderFieldSearch = (status = false) => {
    if (status) {
      return (
        <>
          <div className="search-box chat-search-box">
            <InputGroup size="md" className="mb-3 rounded-lg">
              <span
                className="input-group-text text-muted bg-light pe-1"
                id="basic-addon1"
              >
                <i className="ri-search-line search-icon font-size-18"></i>
              </span>
              <Input
                type="search"
                className={`form-control bg-light ${styles.search}`}
                placeholder="Search Client Name/Email..."
                onChange={querySearch}
              />
            </InputGroup>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <h4 className={attrClass}>{value}</h4>
      {renderFieldSearch(isFieldSearch)}
    </>
  );
}

export default ClientHeaderTab;
