import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDetailHistory } from '../Client/DetailChat/DetailChatClientAPI';
import { apiGetListAgent, apiGetListReport, getListRelatedChat, apiGetDataCSV, apiInitiateChat } from './ReportChatAPI';

const initialState = {
  dataHistoryReportChat: [],
  page: 1,
  totalDataHistory: 0,
  currentPageHistory: 0,
  firstPageHistory: 0,
  lastPageHistory: 0,
  nextPageHistory: '',
  prevPageHistory: '',
  totalPageHistory: 1,
  filterMessage: '',
  filterChatId: '',
  filterNameClient: '',
  filterEmailClient: '',
  filterAgentId: '',
  filterSiteUserUrl: '',
  filterPlatform: '',
  filterStartDate: '',
  filterStartTime:'',
  filterEndDate: '',
  filterEndTime:'',
  filterInitiate: '',
  dataListAgentFilter: [],
  dataChatReplies: [],
  dataChatRelated: [],
  dataDetailReportChat: {},
  chatId:'',
  isModal: false,
  loaderRelated: false,
  loaderDetail: false,
  loaderListReport: false,
  dataExportCsv:[]
};

export const getListReport = createAsyncThunk(
  'ReportChatSetup/getListReport',
  async (params) => {
    const data = {
      page: params.page,
      start_date: params.start_date,
      end_date: params.end_date,
      start_time: params.start_time,
      end_time: params.end_time,
      chat_id: params.chat_id,
      user_name: params.user_name,
      user_email: params.user_email,
      message: params.message,
      agent_id: params.agent_id,
      site_url: params.site_url,
      platform: params.platform,
      initiate: params.initiate,
      with_pagination: true,
      set_per_page: 10,
    };
    const response = await apiGetListReport(data);
    return response.data;
  }
);

export const getDataCSV = createAsyncThunk(
  'ReportChatSetup/getDataCSV',
  async (params) => {
    const data = {
      start_date: params.start_date,
      end_date: params.end_date,
      start_time: params.start_time,
      end_time: params.end_time,
      chat_id: params.chat_id,
      user_name: params.user_name,
      user_email: params.user_email,
      message: params.message,
      agent_id: params.agent_id,
      site_url: params.site_url,
      platform: params.platform,
      initiate: params.initiate,
      with_pagination: false,
      set_per_page: 10,
    };
    const response = await apiGetDataCSV(data);
    return response.data;
  }
);

export const getListChoosedAgent = createAsyncThunk(
  'ReportChatSetup/getListAgent',
  async (token) => {
    const response = await apiGetListAgent(token);
    return response;
  }
);

export const getInitiateChat = createAsyncThunk(
  'ReportChatSetup/initiateChat',
  async (params) => {
    const response = await apiInitiateChat(params);
    return response;
  }
);

export const getDetailReportChat = createAsyncThunk(
  'ReportChatSetup/getDetailChatReport',
  async (chatId) => {
    const response = await getDetailHistory(chatId);
    return response;
  }
);

export const getRelatedChat = createAsyncThunk(
  'ReportChatSetup/getListRelated',
  async (data) => {
    const relatedData = await getListRelatedChat(data);
    const response = relatedData.data;
    return response;
  }
);

export const ReportChatSlice = createSlice({
  name: 'ReportChatSetup',
  initialState,
  reducers: {
    updateDataReportChat: (state, action) => {
      state.dataHistoryReportChat = action.payload;
    },
    updateNumberPage: (state, action) => {
      state.page = action.payload;
    },
    updateFilter: (state, action) => {
      switch (action.payload.field) {
        case 'chat_id':
          state.filterChatId = action.payload.value;
          break;
        case 'nama_client':
          state.filterNameClient = action.payload.value;
          break;
        case 'email_client':
          state.filterEmailClient = action.payload.value;
          break;
        case 'agent':
          state.filterAgentId = action.payload.value;
          break;
        case 'user_site_url':
          state.filterSiteUserUrl = action.payload.value;
          break;
        case 'platform':
          state.filterPlatform = action.payload.value === "0" ? null : action.payload.value ;
          break;
        case 'initiate':
          state.filterInitiate = action.payload.value === "all" ? null : action.payload.value;
          break;
        default:
          state.filterMessage = action.payload.value;
          break;
      }
      return state;
    },
    updateFilterDateRange: (state, action) => {
      if(action.payload.startDate && action.payload.startTime){
        state.filterStartDate = action.payload.startDate;
        state.filterStartTime = action.payload.startTime;
      }else if(action.payload.endDate && action.payload.endTime){
        state.filterEndDate = action.payload.endDate;
        state.filterEndTime = action.payload.endTime;
      }
      return state;
    },
    resetFilter: (state) => {
      state.filterMessage = '';
      state.filterChatId = '';
      state.filterNameClient = '';
      state.filterEmailClient = '';
      state.filterNameAgent = '';
      state.filterEmailAgent = '';
      state.filterStartDate = '';
      state.filterStartTime = '';
      state.filterAgentId = '';
      state.filterEndDate = '';
      state.filterEndTime = '';
      state.filterSiteUserUrl = '';
      return state;
    },
    changeStatusModal: (state, action) => {
      state.isModal = action.payload;
      if (!action.payload) {
        state.dataChatReplies = [];
        state.dataDetailReportChat = {};
      }
    },
    addChatId: (state, action) => {
      state.chatId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListReport.pending, (state) => {
      state.loaderListReport = true;
    });
    builder.addCase(getListReport.fulfilled, (state, action) => {
      state.loaderListReport = false;
      const data = action.payload.data;
      state.dataHistoryReportChat = data.list;
      state.totalDataHistory = parseInt(data.total);
      state.currentPageHistory = data.current_page;
      state.firstPageHistory = data.first_page;
      state.lastPageHistory = data.last_page;
      state.nextPageHistory = data.next_page;
      state.prevPageHistory = data.prev_page;
      state.totalPageHistory = data.total_page;
    });
    builder.addCase(getListReport.rejected, (state) => {
      state.loaderListReport = false;
      state.dataHistoryReportChat = [];
      state.totalDataHistory = 0;
      state.currentPageHistory = 0;
      state.firstPageHistory = 0;
      state.lastPageHistory = 0;
      state.nextPageHistory = '';
      state.prevPageHistory = '';
      state.totalPageHistory = 1;
    });
    builder.addCase(getDataCSV.pending, (state) => {
      state.loaderListReport = true;
      state.dataExportCsv = [];
    });
    builder.addCase(getDataCSV.fulfilled, (state, action) => {
      state.loaderListReport = false;
      const data = action.payload.data;
      state.dataExportCsv = data.list;
    });
    builder.addCase(getDataCSV.rejected, (state) => {
      state.loaderListReport = false;
      state.dataExportCsv = [];
    });
    builder.addCase(getListChoosedAgent.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      state.dataListAgentFilter = data;
      return state;
    });
    builder.addCase(getDetailReportChat.pending, (state) => {
      state.loaderDetail = true;
      state.loaderListReport = true;
    });
    builder.addCase(getDetailReportChat.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      state.loaderListReport = false;
      state.loaderDetail = false;
      state.dataChatReplies = data.chat_reply;
      state.dataDetailReportChat = {
        chat_id: data.chat_id,
        chat_labels: data.chat_labels,
        channel_name: data.channel_name,
        channel_id: data.channel_id,
        agent_name: data.agent_name,
        agent_email: data.agent_email,
        company_name: data.company_name,
        company_uuid: data.company_uuid,
        department_id: data.department_id,
        department_name: data.department_name,
        formatted_date: data.formatted_date,
        message: data.message,
        rating: data.rating,
        topic_id: data.topic_id,
        topic_name: data.topic_name,
        user_email: data.user_email,
        user_name: data.user_name,
        user_phone: data.user_phone,
        user_site_url: data.user_site_url,
        user_ip: data.user_ip,
        user_client_agent: data.user_client_agent,
      };

      state.isModal = true;
      return state;
    }); 
    builder.addCase(getDetailReportChat.rejected, (state) => {
      state.loaderDetail = false;
      state.loaderListReport = false;
    })
    builder.addCase(getRelatedChat.pending, (state) => {
      state.loaderRelated = true;
    })
    builder.addCase(getRelatedChat.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.dataChatRelated = data.list;
      state.loaderRelated = false;
    })
    builder.addCase(getRelatedChat.rejected, (state) => {
      state.loaderRelated = false;
    })
  },
});

export const reportChatSelector = (state) => state.ReportChatSetup;

export const {
  updateDataReportChat,
  updateNumberPage,
  updateFilterDateRange,
  updateFilter,
  resetFilter,
  changeStatusModal,
  addChatId
} = ReportChatSlice.actions;

export default ReportChatSlice.reducer;
