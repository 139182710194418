import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { detailChatClientSelector } from '../../../features/Client/DetailChat/DetailChatClientSlice';

function DivContentEditable(props) {
  const { attrProps, handlerKey, handlerInput } = props;
  const { chatId } = useSelector(detailChatClientSelector);
  const [getStorageMessage, setGetStorageMessage] = useState(
    localStorage.getItem(chatId)
  );
  const ref = useRef(null)

  useEffect(() => {
    ref.current.focus();
    const range = document.createRange();
    range.selectNodeContents(ref.current);
    range.collapse(false);
    const selection = document.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }, []);

  useEffect(() => {
    setGetStorageMessage(localStorage.getItem(chatId));
  }, [chatId]);

  return (
    <div
      {...attrProps}
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: getStorageMessage ? getStorageMessage.replace(/\n/g, '<br>') : getStorageMessage,
      }}
      suppressContentEditableWarning={true}
      onInput={handlerInput}
      onKeyDown={handlerKey}
    ></div>
  );
}

export default DivContentEditable;
