import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { joinRoom } from '../../../Common/WebSocket/Clients/ClientActions';
import {
  getChatAction,
  getClientDetailChat,
  getDetailChat,
  getDetailHistory,
} from './DetailChatClientAPI';

const getTypeConversation = !Boolean(localStorage.getItem('typeConversation'))
  ? 'general'
  : localStorage.getItem('typeConversation');

const initialState = {
  /* info open: not_open|open|error|loader */
  isOpen: 'not_open',

  errorMessage: null,
  chatId: null,
  listBubbleChat: [],
  detailClient: {},
  labelChoosed: [],
  runNavigateClose: false,
  actionHistory: [],
  typeConversation: getTypeConversation,

  /* actionStatus: join|error|null */
  actionStatus: null,

  /* true === open, false === close */
  rightBarMenu: false,
};

/* get chat detail */
export const getChatDetailClient = createAsyncThunk(
  'detailChatCLientSetup/getChatDetail',
  async (chatId, {getState}) => {
    const state = getState();    
    const responseAll = await getDetailChat(chatId);
    const response = responseAll.data
    if (responseAll.status === 200) {
      const dataRes = responseAll.data.data;
      const findTransfer = state.listChatClientSetup.transfer.find(element => element.chat_id === dataRes.chat_id);
      const findPending = state.listChatClientSetup.pending.find(element => element.chat_id === dataRes.chat_id);
      if ([0, 2].includes(dataRes.status) || findTransfer || findPending) {
        joinRoom(dataRes.chat_id);
      }
    }
    const transferList = state.listChatClientSetup.transfer;
    return { response, transferList };
  }
);

/* get chat detail */
export const getChatDetailVirtual = createAsyncThunk(
  'detailChatCLientSetup/getChatDetailVirtual',
  async (chatId) => {
    const response1 = await getDetailChat(chatId);
    const response = response1.data
    return { response };
  }
);

/* get detail client chat */
export const getDetailClientChat = createAsyncThunk(
  'detailChatClientSetup/getDetailClient',
  async (chatId) => {
    const response = await getClientDetailChat(chatId);
    return response.data;
  }
);

export const getDetailHistoryChat = createAsyncThunk(
  'detailChatClientSetup/getDetailHistory',
  async (chatId) => {
    const response = await getDetailHistory(chatId);
    return response;
  }
);

export const getHistoryChatAction = createAsyncThunk(
  'detailChatClientSetup/getHistoryChatAction',
  async (chatId) => {
    const response = await getChatAction(chatId);
    return response;
  }
);

export const DetailChatClientSlice = createSlice({
  name: 'detailChatCLientSetup',
  initialState,
  reducers: {
    updateOpenChat: (state, action) => {
      state.isOpen = 'loader';
      state.chatId = action.payload.chat_id;
    },
    updateIsOpen: (state, action) => {
      state.isOpen = 'open';
      state.chatId = action.payload;
    },
    closeChat: (state) => {
      state.isOpen = 'not_open';
      state.chatId = null;
      state.detailClient = {};
      state.listBubbleChat = [];
      state.runNavigateClose = true;
      state.rightBarMenu = false;
    },
    resolveChat: (state) => {
      state.isOpen = 'open';
      state.chatId = null;
      state.detailClient = {};
      state.runNavigateClose = false;
      state.rightBarMenu = false;
    },
    updateStatusNavigate: (state) => {
      state.runNavigateClose = false;
    },
    addMessage: (state, action) => {
      state.listBubbleChat.push(action.payload);
    },
    changeTypeConversation: (state, action) => {
      state.typeConversation = action.payload;
      localStorage.setItem('typeConversation', action.payload);
    },
    updateStatusRightBar: (state, action) => {
      state.rightBarMenu = action.payload;
    },
    updateLabelChoose: (state, action) => {
      state.labelChoosed = action.payload;
    },
    updateBeforeJoinRoom: (state, action) => {
      const resData = action.payload.chat_detail;
      state.labelChoosed = Boolean(resData.chat_labels)
      ? resData.chat_labels
      : [];

      state.isOpen = 'open';
      state.rightBarMenu = false;
      state.chatId = resData.chat_id;
      state.listBubbleChat = resData.chat_reply;

      state.detailClient = {
        chat_id: resData.chat_id,
        topic_name: resData.topic_name,
        user_name: resData.user_name,
        user_email: resData.user_email,
        user_phone: resData.user_phone,
        agent_id: resData.agent_id,
        agent_name: resData.agent_name,
        agent_email: resData.agent_email,
        status: resData.status,
        channel_name: resData.channel_name,
        channel_id: resData.channel_id,
        department_id: resData.department_id,
        department_name: resData.department_name,
        agent_avatar: resData.agent_avatar,
        avatar: resData.avatar,
      };

      state.actionStatus = 'join';

      

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatDetailClient.pending, (state) => {
        state.rightBarMenu = false;
        state.isOpen = 'loader';
        state.listBubbleChat = [];
        state.labelChoosed = [];
        state.actionStatus = null;
        return state;
      })
      .addCase(getChatDetailClient.fulfilled, (state, action) => {
        const resData = action.payload.response.data;
        const findTransferList = action.payload.transferList.find(element => element.chat_id === resData.chat_id);
        if(findTransferList){
          state.labelChoosed = Boolean(resData.chat_labels)
            ? resData.chat_labels
            : [];
          state.isOpen = 'open';
          state.rightBarMenu = false;
          state.detailClient = {
            chat_id: resData.chat_id,
            topic_name: resData.topic_name,
            user_name: resData.user_name,
            user_email: resData.user_email,
            user_phone: resData.user_phone,
            agent_id: resData.agent_id,
            agent_name: resData.agent_name,
            agent_email: resData.agent_email,
            status: resData.status,
            channel_name: resData.channel_name,
            channel_id: resData.channel_id,
            department_id: resData.department_id,
            department_name: resData.department_name,
            agent_avatar: resData.agent_avatar,
            avatar: resData.avatar,
            transfer: true
          };

          state.chatId = resData.chat_id;

          state.listBubbleChat = resData.chat_reply;
          let lastBubbleChat = resData.chat_reply[state.listBubbleChat.length - 1].message;
          localStorage.setItem('messageVirtual', lastBubbleChat);

          state.actionStatus = 'join';
        }else{
          state.labelChoosed = Boolean(resData.chat_labels)
            ? resData.chat_labels
            : [];
          state.isOpen = 'open';
          state.rightBarMenu = false;
          state.detailClient = {
            chat_id: resData.chat_id,
            topic_name: resData.topic_name,
            user_name: resData.user_name,
            user_email: resData.user_email,
            user_phone: resData.user_phone,
            agent_id: resData.agent_id,
            agent_name: resData.agent_name,
            agent_email: resData.agent_email,
            status: resData.status,
            channel_name: resData.channel_name,
            channel_id: resData.channel_id,
            department_id: resData.department_id,
            department_name: resData.department_name,
            agent_avatar: resData.agent_avatar,
            avatar: resData.avatar,
          };

          state.chatId = resData.chat_id;

          state.listBubbleChat = resData.chat_reply;
          let lastBubbleChat = resData.chat_reply[state.listBubbleChat.length - 1].message;
          localStorage.setItem('messageVirtual', lastBubbleChat);


          state.actionStatus = 'join';
        }
        state.typeConversation = resData.type;
        localStorage.setItem('typeConversation', resData.type);
        return state;
      })
      .addCase(getChatDetailClient.rejected, (state, action) => {
        state.isOpen = 'error';
        state.chatId = null;
        state.listBubbleChat = [];
        state.detailClient = {};
        state.labelChoosed = [];

        state.actionStatus = 'error';

        return state;
      })
      .addCase(getChatDetailVirtual.pending, (state) => {
        state.rightBarMenu = false;
        state.isOpen = 'loader';
        state.listBubbleChat = [];
        state.labelChoosed = [];
        state.actionStatus = null;
        return state;
      })
      .addCase(getChatDetailVirtual.fulfilled, (state, action) => {
        const resData = action.payload.response.data;
        state.labelChoosed = Boolean(resData.chat_labels)
            ? resData.chat_labels
            : [];
          state.isOpen = 'open';
          state.rightBarMenu = false;
          state.detailClient = {
            chat_id: resData.chat_id,
            topic_name: resData.topic_name,
            user_name: resData.user_name,
            user_email: resData.user_email,
            user_phone: resData.user_phone,
            agent_id: resData.agent_id,
            agent_name: resData.agent_name,
            agent_email: resData.agent_email,
            status: resData.status,
            channel_name: resData.channel_name,
            channel_id: resData.channel_id,
            department_id: resData.department_id,
            department_name: resData.department_name,
            agent_avatar: resData.agent_avatar,
            avatar: resData.avatar,
          };

          state.chatId = resData.chat_id;

          state.listBubbleChat = resData.chat_reply;
          let lastBubbleChat = resData.chat_reply[state.listBubbleChat.length - 1].message;
          localStorage.setItem('messageVirtual', lastBubbleChat);

          state.actionStatus = 'join';
          state.typeConversation = 'faq';
          localStorage.setItem('typeConversation', 'faq');
        return state;
      })
      .addCase(getChatDetailVirtual.rejected, (state, action) => {
        state.isOpen = 'error';
        state.chatId = null;
        state.listBubbleChat = [];
        state.detailClient = {};
        state.labelChoosed = [];

        state.actionStatus = 'error';

        return state;
      })
      .addCase(getDetailHistoryChat.pending, (state) => {
        state.rightBarMenu = false;
        state.isOpen = 'loader';
        state.listBubbleChat = [];
        state.labelChoosed = [];
        state.actionStatus = null;
        state.detailClient = {};
        return state;
      })
      .addCase(getDetailHistoryChat.fulfilled, (state, action) => {
        const resData = action.payload.data.data;

        state.labelChoosed = Boolean(resData.chat_labels)
          ? resData.chat_labels
          : [];
        state.isOpen = 'open';
        state.rightBarMenu = false;
        state.detailClient = {
          chat_id: resData.chat_id,
          topic_name: resData.topic_name,
          user_name: resData.user_name,
          user_email: resData.user_email,
          user_phone: resData.user_phone,
          no_whatsapp: resData.no_whatsapp,
          status: resData.status,
          channel_name: resData.channel_name,
          channel_id: resData.channel_id,
          department_name: resData.department_name,
          department_id: resData.department_id,
          agent_avatar: resData.agent_avatar,
          avatar: resData.avatar,
        };

        state.chatId = resData.chat_id;

        state.listBubbleChat = resData.chat_reply;

        state.actionStatus = 'join';

        return state;
      })
      .addCase(getDetailHistoryChat.rejected, (state) => {
        state.isOpen = 'error';
        state.chatId = null;
        state.listBubbleChat = [];
        state.detailClient = {};
        state.labelChoosed = [];

        state.actionStatus = 'error';

        return state;
      })
      .addCase(getHistoryChatAction.pending, (state) => {
        state.actionHistory = [];
        return state;
      })
      .addCase(getHistoryChatAction.fulfilled, (state, action) => {
        const resData = action.payload.data;
        state.actionHistory = resData.data;
        return state;
      })
      .addCase(getHistoryChatAction.rejected, (state) => {
        state.isOpen = 'error';
        state.chatId = null;
        state.listBubbleChat = [];
        state.detailClient = {};
        state.labelChoosed = [];
        state.actionHistory = [];
        state.actionStatus = 'error';

        return state;
      });
  },
});

export const detailChatClientSelector = (state) => state.detailChatCLientSetup;

export const {
  updateOpenChat,
  closeChat,
  resolveChat,
  addMessage,
  updateStatusNavigate,
  updateStatusRightBar,
  updateLabelChoose,
  updateBeforeJoinRoom,
  changeTypeConversation,
  updateIsOpen
} = DetailChatClientSlice.actions;

export default DetailChatClientSlice.reducer;
