import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/* reduxer */

/* component */
import { TabContent } from 'reactstrap';
import { detailChatClientSelector, getChatDetailClient, updateOpenChat } from '../../../DetailChat/DetailChatClientSlice';
import { listChatClientSelector } from './ListChatClientSlice';
import OnGoing from './OnGoing';
import Pending from './Pending';
import Transfer from './Transfer';
import { authSelector } from '../../../../../app/Auth/AuthSlice';
import { changeTabMenu } from '../../../../../app/Layouts/LayoutSlice';
import { resetAllStateInputChat, updateMessage } from '../../../../../Common/Components/InputChat/InputChatSlice';

function ListChat(props) {
  /* configuration */
  const { activeMenu } = props;

  /* selector */
  const {
    pending,
    transfer,
    ongoing,
    loader_pending,
    loader_ongoing,
    loader_transfer,
  } = useSelector(listChatClientSelector);
  const { user } = useSelector(authSelector)
  const { chatId } = useSelector(detailChatClientSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlerResetAllInputMessage = () => {
    const elmessagePreview = document.getElementById('messagePreview');
    const elmessageDetailChat = document.getElementById('input-message');
    if (elmessageDetailChat) {
      elmessageDetailChat.innerHTML = '';
    }

    if (elmessagePreview) {
      elmessagePreview.innerHTML = '';
    }
  };

  useEffect(() => {
    if(pending){
      pending.forEach(chat => {
        if (parseInt(chat.assign_to) === user.agent_id) {
          dispatch(updateOpenChat({chat_id: chat.chat_id}));
          navigate(`../chat-with-client/${chat.chat_id}-CP`);
          dispatch(changeTabMenu('Chat-w-clients'));
        }
    });
    }
  }, [pending])
  

  /* return view */
  return (
    <Fragment>
      <TabContent activeTab={activeMenu}>
        <Pending
          data={pending}
          isLoader={loader_pending}
          chatIdActive={chatId}
        />
        <Transfer
          data={transfer}
          isLoader={loader_transfer}
          chatIdActive={chatId}
        />
        <OnGoing
          data={ongoing}
          isLoader={loader_ongoing}
          chatIdActive={chatId}
        />
      </TabContent>
    </Fragment>
  );
}

export default ListChat;
