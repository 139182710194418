import Service from '../../Common/service';

/* get detail chat */
export const apiGetListReport = async (params) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const path = `api-socket/chats/resolve-chat-from-backups`;
  const data = JSON.stringify(params);

  const response = await Service.getListReport(path, data, config);
  return response;
};

export const apiInitiateChat = async (params) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const path = `initiate-chat`;
  const data = JSON.stringify(params);

  const response = await Service.getInitiateChat(path, data, config);
  return response;
};

export const apiGetDataCSV = async (params) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const path = `api-socket/chats/export-csv`;
  const data = JSON.stringify(params);

  const response = await Service.getDataCSV(path, data, config);
  return response;
};

export const apiGetListAgent = async (token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'xmlhttprequest',
      Authorization: `Bearer ${token}`,
    },
  };

  const path = `api/agent/all-agents`;
  const data = {
    id_roles: 4,
    id_department: null,
  };

  const response = await Service.getListAgent(path, data, config);
  return response;
};

export const getListRelatedChat = async (data) => {
  const path = 'api-socket/chats/related-chat';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await Service.getRelatedChats(path, data, config);
  return response;
};
