import { receiveIrsfaMessage } from "../../../features/Client/Tabs/ChatWithClients/ListChat/ListChatClientAPI";


const ClientIrsfaListens = (SocketIrsfa) => {
  // SocketIrsfa.on("receive.message", (data) => {
  //   if(data.from !== 'status@broadcast'){
  //     receiveIrsfaMessage(data);
  //   }
  // });
};

export default ClientIrsfaListens;
