import React, { Fragment, useEffect } from 'react';

/* reduxer */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { layoutSetupSelector } from '../../../app/Layouts/LayoutSlice';
import ReportChat from '../../ReportChat';
import {
  detailChatClientSelector,
  getChatDetailClient,
  getChatDetailVirtual,
  updateStatusNavigate,
} from './DetailChatClientSlice';
import Error from './Error';

/* component */
import NotOpened from './NotOpened';
import Opened from './Opened';

const Features = (params) => {
  const { isOpen, layoutMode, listBubbleChat, detailClient } = params;
  if (isOpen === 'not_open') {
    return <NotOpened layoutMode={layoutMode} />;
  } else if (isOpen === 'error') {
    return <Error layoutMode={layoutMode} />;
  } else {
    return (
      <Opened
        statusDetail={isOpen}
        detailClient={detailClient}
        listBubbleChat={listBubbleChat}
      />
    );
  }
};

function DetailChat(props) {
  /* config */
  const { chatId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* selector from reducer*/
  const { layoutMode, activeTab } = useSelector(layoutSetupSelector);
  const { isOpen, listBubbleChat, detailClient, runNavigateClose } =
    useSelector(detailChatClientSelector);

  const typeConversation = localStorage.getItem('typeConversation')
  useEffect(() => {
    if (runNavigateClose) {
      navigate('/chat-with-client');
      dispatch(updateStatusNavigate());
    }
  }, [runNavigateClose]);

  useEffect(() => {
    if (Boolean(chatId)) {
      setTimeout(() => {
        const splitParams = chatId.split('-');
        if (splitParams[1] !== 'CH' && typeConversation !== 'faq') {
          dispatch(getChatDetailClient(splitParams[0]));
        }else if(splitParams[1] !== 'CH' && typeConversation === 'faq'){
          dispatch(getChatDetailVirtual(splitParams[0]));
        }
      }, 1500);
    }
  }, []);

  return (
    <Fragment>
      {activeTab === 'Report-Chat-w-history' && <ReportChat />}

      {activeTab !== 'Report-Chat-w-history' && (
        <div
          className={`w-100 ${
            isOpen === 'open' ? 'user-chat' : 'non-user-chat'
          } user-chat-show`}
        >
          <Features
            isOpen={isOpen}
            layoutMode={layoutMode}
            listBubbleChat={listBubbleChat}
            detailClient={detailClient}
          />
        </div>
      )}
    </Fragment>
  );
}

export default DetailChat;
