import Service from '../../../../../Common/service';

export const getListChatCLient = () => {
  return Service.getListChatClient()
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getListHistoryChat = async (data) => {
  const path = 'api-socket/chats/resolve-chat-from-backups';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await Service.getListResolveChat(path, data, config);
  return response;
};

export const searchChatHistory = async (data) => {
  const path = 'api-socket/chats/resolve-chat-from-backups/search';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await Service.searchHistoryChat(path, data, config);
  return response;
};

export const receiveIrsfaMessage = async (data) => {
  const path = 'whatsapp-irsfa';
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await Service.receiveIrsfaMessage(path, data, config);
  return response;
}
