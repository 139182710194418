import React from 'react';
import Linkify from '../Linkify/Linkify';

function Message(props) {
  const { message, className } = props;

  if (!Boolean(message)) {
    return <p className="mb-0"></p>;
  } else {
    return <Linkify content={message} tagName="p" className={className} />;
  }
}

export default Message;
