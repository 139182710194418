import React from 'react';
import { Card, UncontrolledTooltip } from 'reactstrap';
import { limitText, randomString } from '../../utils/helpers';
import FancyApp from '../FancyApp/FancyApp';
import fileSaver from 'file-saver';

const Image = React.memo(
  function ({ src }) {
    return <img src={src} alt="chat" className="mx-auto rounded" />;
  },
  (prevProps, nextProps) => {
    return prevProps.src === nextProps.src;
  }
);

function MessageFile(props) {
  const { data, extensionFile, categoryFile } = props;
  const configFancyApps = {
    Toolbar: {
      display: ['zoom', 'close'],
      items: {
        zoom: {
          type: 'button',
          class: 'fancybox__button--zoom',
          label: 'TOGGLE_ZOOM',
          html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><circle cx="10" cy="10" r="7"></circle><path d="M16 16 L21 21"></svg>',
          click: function (event) {
            event.preventDefault();

            const panzoom = this.fancybox.getSlide().Panzoom;

            if (panzoom) {
              panzoom.toggleZoom();
            }
          },
        },
        close: {
          type: 'button',
          label: 'CLOSE',
          class: 'fancybox__button--close',
          html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M20 20L4 4m16 0L4 20"></path></svg>',
          tabindex: 1,
          click: function (event) {
            event.stopPropagation();
            event.preventDefault();

            this.fancybox.close();
          },
        },
      },
    },
    Image: {
      zoom: true,
    },
  };

  const handlerDownloadFile = (fileUrl, fileName) => {
    return fileSaver.saveAs(fileUrl, fileName);
  };

  const Items = (params) => {
    const { data, extensionFile, categoryFile } = params;
    if (categoryFile.name === 'image') {
      return (
        <>
          <FancyApp options={configFancyApps}>
            <a
              data-fancybox={`image-chat-${randomString(15)}`}
              data-type="image"
              data-preload="true"
              href={data.file_url}
              data-caption={
                !Boolean(data.message) ? '' : limitText(data.message, 0, 100)
              }
              className={`img-parent popup-img d-inline-block m-1 text-center rounded ${
                ['svg', 'png'].includes(extensionFile)
                  ? 'with-bg-transparent'
                  : ''
              }`}
              title="show image"
            >
              <Image src={data.file_url} />
            </a>
          </FancyApp>
          <div>
            <button
              onClick={() => handlerDownloadFile(data.file_url, data.file_name)}
              className="bg-transparent border-0 text-white text-end"
              title="download"
            >
              <i className="ri-download-2-line"></i>
            </button>
          </div>
        </>
      );
    }

    let iconFile = '';
    if (categoryFile.name === 'video') {
      iconFile = 'fas fa-file-video';
    } else if (categoryFile.name === 'archived') {
      iconFile = 'fa-solid fa-file-zipper';
    } else if (categoryFile.name === 'code') {
      iconFile = 'fas fa-file-code';
    } else {
      switch (extensionFile) {
        case 'crt':
          iconFile = 'fas fa-file-certificate';
          break;
        case 'csr':
          iconFile = 'fas fa-file-certificate';
          break;
        case 'pdf':
          iconFile = 'fas fa-file-pdf';
          break;
        case 'doc':
          iconFile = 'fas fa-file-word';
          break;
        case 'docx':
          iconFile = 'fas fa-file-word';
          break;
        case 'xls':
          iconFile = 'fas fa-file-excel';
          break;
        case 'xlsx':
          iconFile = 'fas fa-file-excel';
          break;
        case 'csv':
          iconFile = 'fas fa-file-csv';
          break;
        default:
          iconFile = 'fas fa-file';
          break;
      }
    }

    const specialId = `fileName-${randomString(10)}-${data.file_id}`;
    const cardClass = Boolean(data.agent_name)
      ? 'card-file-right'
      : 'card-file-left';
    return (
      <>
        <Card className={`p-2 mb-2 file-text-responsived ${cardClass}`}>
          <div className="d-flex align-items-center">
            <div className="avatar-sm me-3 ms-0">
              <div className="avatar-title card-avatar-file rounded">
                <i className={iconFile}></i>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="text-start">
                <h5
                  className="font-size-14 mb-1 d-inline-block text-truncate text-avatar-header"
                  style={{ cursor: 'help' }}
                  id={specialId}
                >
                  {data.file_name}
                </h5>
                <UncontrolledTooltip target={specialId} placement="top">
                  {data.file_name}
                </UncontrolledTooltip>
                <p className="text-muted-left-file font-size-13 mb-0">
                  {data.file_type === 'other' ? 'document' : data.file_type}
                </p>
              </div>
            </div>

            <div className="ms-4">
              <ul className="list-inline mb-0 font-size-20">
                <li className="list-inline-item">
                  <a
                    href="#"
                    className="text-white text-end"
                    onClick={() => handlerDownloadFile(data.file_url)}
                  >
                    <i className="ri-download-2-line"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </>
    );
  };

  return (
    <div className="message-img mb-0">
      <div>
        <Items
          data={data}
          extensionFile={extensionFile}
          categoryFile={categoryFile}
        />
      </div>
    </div>
  );
}

export default MessageFile;
