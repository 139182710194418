import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import BubbleChatClient from '../../Common/Components/BubbleChat/BubbleChatClient';
import CustomCollapse from '../../Common/Components/CustomCollapse/CustomCollapse';
import ClientHeaderTab from '../../Common/Components/HeaderTab/ClientHeaderTab';
import ModalFullScreen from '../../Common/Components/Modal/ModalFullScreen';
import ModalMd from '../../Common/Components/Modal/ModalMd';
import TableComp from '../../Common/Components/TableComp';
import {
  changeStatusModal,
  getDetailReportChat,
  getRelatedChat,
  getListReport,
  reportChatSelector,
  updateNumberPage,
  addChatId,
  getDataCSV,
  getInitiateChat,
} from './ReportChatSlice';
import { sendChatHistory } from '../Client/DetailChat/DetailChatClientAPI';
import {notify} from '../../Common/utils/helpers';
import { authSelector } from '../../app/Auth/AuthSlice';
import ClientListChat from '../../Common/Components/ListChat/ClientListChat';
import SkeletonListChat from '../../Common/Components/Skeletons/SkeletonListChat';
import LoaderSpinner from '../../Common/Components/Loader/LoaderSpinner';
import { onlineUsersSelector } from '../Client/Tabs/ChatWithClients/OnlineUsers/OnlineUsersSlice';
import { sendMessage } from '../../Common/WebSocket/Clients/ClientActions';
import { resetAllStateInputChat, updateMessage } from '../../Common/Components/InputChat/InputChatSlice';
import { getChatDetailClient, updateOpenChat } from '../Client/DetailChat/DetailChatClientSlice';
import { changeTabMenu } from '../../app/Layouts/LayoutSlice';
import Socket from '../../Common/WebSocket/Socket';

function ReportChat() {
  const dispatch = useDispatch();
  const refBubbleReportChat = useRef();
  const { user } = useSelector(authSelector);
  const { agent_online } = useSelector(onlineUsersSelector);
  const [headerTable, setHeaderTable] = useState([]);
  const [dataBodyTable, setDataBodyTable] = useState([]);
  const [dataCountTable, setDataCountTable] = useState({});
  const [loaderSendHistoryChat, setLoaderSendHistoryChat] = useState(false);
  const [isModalSendChat, setIsModalSendChat] = useState(false);
  const [isModalInitiate, setIsModalInitiate] = useState(false);
  const [emailClient, setEmailClient] = useState('');
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [detailObjectTabel, setDetailObjectTabel] = useState({});
  const {
    dataHistoryReportChat,
    page,
    filterMessage,
    filterChatId,
    filterNameClient,
    filterEmailClient,
    filterAgentId,
    filterSiteUrl,
    filterPlatform,
    filterInitiate,
    filterStartDate,
    filterEndDate,
    filterStartTime,
    filterEndTime,
    totalDataHistory,
    currentPageHistory,
    totalPageHistory,
    dataDetailReportChat,
    dataChatReplies,
    dataChatRelated,
    loaderRelated,
    loaderDetail,
    chatId,
    isModal,
    loaderListReport
  } = useSelector(reportChatSelector);

  const navigate = useNavigate();

  const handlerGetListReport = () => {
    const params = {
      page: page,
      message: filterMessage,
      chat_id: !filterChatId ? '' : [filterChatId],
      user_name: filterNameClient,
      user_email: filterEmailClient,
      site_url: filterSiteUrl,
      platform: filterPlatform,
      initiate: filterInitiate,
      start_date: filterStartDate,
      end_date: filterEndDate,
      start_time: filterStartTime,
      end_time: filterEndTime,
      agent_id: filterAgentId,
    };
    dispatch(getListReport(params));
  };

  const handlerChangePage = (val) => {
    dispatch(updateNumberPage(val));
  }; 

  const toggleHandlerModalHistoryChat = (params) => {
    if (loaderSendHistoryChat) {      
      setIsModalSendChat(true);      
    } else {
      const status = isModalSendChat ? false : true;
      setIsModalSendChat(status);
      setDetailObjectTabel(params)
    }
  };

  const handlerModalInitiate = (params) => {
    setIsModalInitiate(!isModalInitiate);
    setDetailObjectTabel(params)
  };

  const handlerModalReportChat = () => {
    const isStatus = !isModal ? true : false;
    dispatch(changeStatusModal(isStatus));
  };

  const handlerChangeEmailClient = (event) => {
    setEmailClient(event.target.value);
  };

  const showDetailReport = (chatId, noTelp, channel_id) => {
    dispatch(getDetailReportChat(chatId));
    dispatch(addChatId(chatId));
    const data = {
      with_pagination: true,
      company_uuid: user.uuid,
      set_per_page: 30,
      page: 1,
      noTelp: noTelp,
      channel_id: channel_id
    }
    dispatch(getRelatedChat(data));
  };

  const handlerSendHistory = () => {
    dataHistoryReportChat.map((obj) => {
      if (!obj.user_email) {
        return notify('error', 3000, `Email client is required!`);
      }
      setLoaderSendHistoryChat(true);

    const data = {
      chat_id: obj.chat_id,
      user_email: obj.user_email,
    };

    sendChatHistory(data)
      .then((response) => {
        setLoaderSendHistoryChat(false);
        return notify('success', 3000, `Send history chat success!`);
      })
      .catch((err) => {
        setLoaderSendHistoryChat(false);
        return notify('error', 3000, `Send history failed, please try again!`);
      });
    })
  };

  const handlerResetAllInputMessage = () => {
    const elmessagePreview = document.getElementById('messagePreview');
    const elmessageDetailChat = document.getElementById('input-message');
    if (elmessageDetailChat) {
      elmessageDetailChat.innerHTML = '';
    }

    if (elmessagePreview) {
      elmessagePreview.innerHTML = '';
    }
  };

  const handlerInitiateChat = () => {
    let messageClient = document.getElementById("messageInitiate").value;
    let selectedAgentId = document.getElementById("select-agent");
    if(!messageClient){
      return notify('error', 3000, `Initiate chat failed, Message is empty!`);
    }
    
    setIsModalInitiate(false)

    if(selectedAgentId){
      dispatch(getInitiateChat({detailObjectTabel, agentid:selectedAgentId.value}))
      .then((response) => {
        let user_transfer = agent_online.find(agent => agent.id === parseInt(selectedAgentId.value));

        const agent_name = user_transfer.agent_name;
        const agent_avatar = user_transfer.agent_avatar;
        const avatar = user_transfer.avatar;
        const dataFile = {
          file_type: null,
          file_name: null,
          file_url: null,
          file_path: null,
          file_id: null,
        };
        sendMessage(response.payload.data.data, messageClient, dataFile, agent_name, agent_avatar, avatar);
        notify('success', 3000, `Initiate chat success`);
      })
      .catch((err) => {
        return notify('error', 3000, `Initiate chat failed, please try again!`);
      });
    }else{
      dispatch(getInitiateChat({detailObjectTabel}))
      .then((response) => {
        dispatch(updateOpenChat({chat_id: response.payload.data.data}));
        navigate(`../chat-with-client/${response.payload.data.data}-CP`);
        dispatch(changeTabMenu('Chat-w-clients'));

        handlerResetAllInputMessage();
        dispatch(resetAllStateInputChat());

        const agent_name = user.agent_name;
        const agent_avatar = user.agent_avatar;
        const avatar = user.avatar;
        const dataFile = {
          file_type: null,
          file_name: null,
          file_url: null,
          file_path: null,
          file_id: null,
        };
        sendMessage(response.payload.data.data, messageClient, dataFile, agent_name, agent_avatar, avatar);
        dispatch(updateMessage(''));
        dispatch(getChatDetailClient(response.payload.data.data));
      })
      .catch((err) => {
        return notify('error', 3000, `Initiate chat failed, please try again!`);
      });
    }
  }

  const Items = (params) => {
    const { isLoader, data } = params;
    if (isLoader) {
      return <SkeletonListChat />;
    }

    if (data.length < 1) {
      return (
        <li id="conversation-chat-empty" className="active">
          <div className="d-flex justify-content-center p-5">
            <h5 className="mb-2 font-size-14">Chat Not Found!</h5>
          </div>
        </li>
      );
    }

    return data.map((val, index) => {
      let isActive = '';
      if (chatId === val.chat_id) {
        isActive = 'active';
      }
      

      return (
        <ClientListChat
          key={index}
          index={index}
          data={val}
          isActive={`listChatClient ${isActive}`}
          chatIdActive={chatId}
          detailType="CP"
          relatedChat={true}
        />
      );
    });
  };

  const handlerCollapseAbout = () => {
    setOpenCollapse(!openCollapse)
  }

  const handlerCollapseRelated = () => {
    setOpenCollapse2(!openCollapse2)
  }

  const handlerExportCSV = () => {
    const params = {
      page: page,
      message: filterMessage,
      chat_id: !filterChatId ? '' : [filterChatId],
      user_name: filterNameClient,
      user_email: filterEmailClient,
      site_url: filterSiteUrl,
      platform: filterPlatform,
      initiate: filterInitiate,
      start_date: filterStartDate,
      end_date: filterEndDate,
      agent_id: filterAgentId,
    };

    dispatch(getDataCSV(params))
    .then((data) => {
      if (data.payload.data.list && data.payload.data.list.length > 0) {
        let dataCSV = data.payload.data.list;

        const channelMapping = {
          1: 'Livechat',
          2: 'Whatsapp',
          3: 'Telegram',
          4: 'Whatsapp Irsfa'
        };

        const csvContent = ["Chat ID,Client Name,Agent Name,Date,Average Time Response,Platform,Rating"];

        dataCSV.forEach(row => {
          const { chat_id, agent_name, user_name, created_at, average_time, channel_id, rating } = row;

          const platform = channelMapping[channel_id] || 'N/A';

          const formattedRating = rating !== null && rating !== undefined ? rating : 'N/A';

          const wrappedUserName = user_name.includes(',') ? `"${user_name}"` : user_name;

          const rowValues = [chat_id, wrappedUserName, agent_name, created_at, average_time, platform, formattedRating];
          const csvRow = rowValues.map(value => value === null || value === undefined ? 'N/A' : value).join(",");
          csvContent.push(csvRow);
        });

        const csvBlob = new Blob([csvContent.join("\n")], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvBlob);

        window.open(csvUrl, '_blank');

        URL.revokeObjectURL(csvUrl);
      } else {
        console.error('No data to export');
      }
    })
    .catch((error) => {
      console.error('Error exporting CSV:', error);
    });
  }
  

  useEffect(() => {     
    if (totalDataHistory > 0) {    
      const listBody = [];
      dataHistoryReportChat.map((obj) => {
        let data = {
          chat_id: (
            <Link
              to="#"
              className="text-tangerin text-decoration-underline"
              onClick={() => showDetailReport(obj.chat_id, obj.no_whatsapp ? obj.no_whatsapp : obj.no_telegram, obj.channel_id)}
            >
              {obj.chat_id}
            </Link>
          ),
          message: obj.message,
          user_name: obj.user_name,
          agent_name: obj.agent_name,
          created_at: obj.created_at, 
          average_time: obj.average_time_human ? obj.average_time_human : "N/A",
          platform: obj.channel_id === 1 ? "Livechat" : obj.channel_id === 2 ? "Whatsapp" : obj.channel_id === 3 ? "Telegram" : "Whatsapp Irsfa",
          rating: obj.rating ? obj.rating : "N/A",
          send_history: (
            <div>
              <button
                type="button"
                id="forwardChat"
                className="btn text-tangerin p-0 m-0"
                onClick={()=>toggleHandlerModalHistoryChat(obj)}
              >
               <p className='m-0 p-0'> <i className="ri-chat-forward-fill m-2"></i> Send History Chat</p>
              </button>
            </div>
          ),
          initiate_chat: obj.channel_id === 1 || obj.channel_id === 4 ? '-' : (
            <div>
              <button
                type="button"
                id="initiateChat"
                className="btn text-tangerin p-0 m-0"
                onClick={()=>handlerModalInitiate(obj)}
              >
               <p className='m-0 p-0'>Initiate Chat</p>
              </button>
            </div>
          )
        };
        return listBody.push(data);
      });
      setDataBodyTable(listBody);

      const countingTable = {
        current: currentPageHistory,
        total_data: totalDataHistory,
        total_page: totalPageHistory,
      };

      setDataCountTable(countingTable);
    } else {
      setDataBodyTable([]);

      const countingTable = {
        current: 0,
        total_data: 0,
        total_page: 0,
      };
      setDataCountTable(countingTable);
    }
  }, [
    totalDataHistory,
    currentPageHistory,
    totalDataHistory,
    totalPageHistory,
  ]);

  useEffect(() => {
    const header = [
      {
        title: 'Chat Id',
        short: false,
        field: 'chat_id',
      },
      {
        title: 'Message',
        short: false,
        field: 'message',
      },
      {
        title: 'Client Name',
        short: false,
        field: 'user_name',
      },
      {
        title: 'Agent Name',
        short: false,
        field: 'agent_name',
      },
      {
        title: 'Date',
        short: false,
        field: 'created_at',
      },
      {
        title: 'Average Time Response',
        short: false,
        field: 'average_time',
      },
      {
        title: 'Platform',
        short: false,
        field: 'platform',
      },
      {
        title: 'Rating',
        short: false,
        field: 'rating',
      },
      {
        title: 'Send History Chat',
        short: false,
        field: 'send_history',
      },
      {
        title: 'Initiate Chat',
        short: false,
        field: 'initiate_chat',
      },
    ];

    setHeaderTable(header);

    handlerGetListReport();
  }, []);

  useEffect(() => {
    handlerGetListReport();
  }, [page]);

  return (
    <>
      <div className="w-100 border-start" style={{ overflowX: 'hidden' }}>
        <SimpleBar
          style={{ maxHeight: '100%' }}
          className="p-3 report-filter-desc"
        >
          <div className="row">
            <div className="col-12">
              <div className="px-2 py-3">
                <ClientHeaderTab value="List Report Chat" />
                <Button
                  type="button"
                  color="tangerin"
                  className="text-center"
                  onClick={handlerExportCSV}
                >
                  Export CSV
                </Button>
              </div>
            </div>
            <div className="col-12">
              <div className="px-2 py-2">
                <TableComp
                  theadData={headerTable}
                  dataTBody={dataBodyTable}
                  countTable={dataCountTable}
                  paginateClick={handlerChangePage}
                  loader={loaderListReport}
                />
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <ModalFullScreen
        isOpen={isModal}
        handlerFunc={handlerModalReportChat}
        title="Detail Report Chat"
        headerModal={true}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        backdrop="static"
      >
        <div className="row">
          <div className="col-8">
            <SimpleBar
              ref={refBubbleReportChat}
              className="report-detail-chat-conversation px-3"
              id="messages"
            >
              {loaderDetail ? <LoaderSpinner /> : (
                <ul className="list-unstyled mb-0">
                  {dataChatReplies.map((val, index) => {
                    return <BubbleChatClient key={index} data={val} />;
                  })}
                </ul>
              )}
            </SimpleBar>
          </div>
          <div className="col-4 border-start border-5">
            <SimpleBar className="report-detail-chat-conversation px-3">
              <Card className="mb-1 shadow-done border">
                <CustomCollapse
                  title="About"
                  iconClass="ri-user-line"
                  isOpen={openCollapse}
                  toggleCollapse={handlerCollapseAbout}
                >
                  <div>
                    <p className="text-muted mb-1">Chat ID</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.chat_id
                        ? '-'
                        : dataDetailReportChat.chat_id}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Username</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_name
                        ? '-'
                        : dataDetailReportChat.user_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Email</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_email
                        ? '-'
                        : dataDetailReportChat.user_email}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Phone</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_phone
                        ? '-'
                        : dataDetailReportChat.user_phone}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">First Chat</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.message
                        ? '-'
                        : dataDetailReportChat.message}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Url</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_site_url
                        ? '-'
                        : dataDetailReportChat.user_site_url}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client IP</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_ip
                        ? '-'
                        : dataDetailReportChat.user_ip}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Client Browser Access</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.user_client_agent
                        ? '-'
                        : dataDetailReportChat.user_client_agent}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Channel Name</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.channel_name
                        ? '-'
                        : dataDetailReportChat.channel_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Department Name</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.department_name
                        ? '-'
                        : dataDetailReportChat.department_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Topic</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.topic_name
                        ? '-'
                        : dataDetailReportChat.topic_name}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Rate From Client</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.rating
                        ? '-'
                        : dataDetailReportChat.rating}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">First Chat Time</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.formatted_date
                        ? '-'
                        : dataDetailReportChat.formatted_date}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Agent Name</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.formatted_date
                        ? '-'
                        : dataDetailReportChat.formatted_date}
                    </h5>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Agent Email</p>
                    <h5 className="font-size-14">
                      {!dataDetailReportChat.formatted_date
                        ? '-'
                        : dataDetailReportChat.formatted_date}
                    </h5>
                  </div>                  
                </CustomCollapse>
              </Card>
              <Card className="mb-1 shadow-done border">
                <CustomCollapse
                  title="Related Chat"
                  iconClass="ri-chat-history-line"
                  isOpen={openCollapse2}
                  style={{overflow: 'hidden scroll', maxHeight: '550px'}}
                  className={true}
                  toggleCollapse={handlerCollapseRelated}
                >
                  <ul
                    className="list-unstyled chat-list chat-user-list"
                    id="chat-list"
                  >
                    <Items isLoader={loaderRelated} data={dataChatRelated} />
                  </ul>
                </CustomCollapse>
              </Card>
            </SimpleBar>
          </div>
        </div>        
      </ModalFullScreen>
      <div>
        <ModalMd
          isOpen={isModalSendChat}
          handlerFunc={toggleHandlerModalHistoryChat}
          title="Send History Chat"
          headerModal={true}
          unmountOnClose={true}
          keyboard={true}
          centered={true}
          backdrop="static"
        >
          <Form as="div">
            <Form.Group className="mb-3" controlId="ChatIDForm">
              <Form.Label>Chat ID</Form.Label>
              <p>{detailObjectTabel.chat_id}</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="EmailForm">
              <Form.Label>
                Email Client <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                onChange={handlerChangeEmailClient}
                type="email"
                value={detailObjectTabel.user_email}
                placeholder="Type email client"
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-tangerin"
                onClick={handlerSendHistory}
                disabled={loaderSendHistoryChat ? true : false}
              >
                {loaderSendHistoryChat ? 'Loading...' : 'Send'}
              </button>
            </div>
          </Form>
        </ModalMd>
        <ModalMd
          isOpen={isModalInitiate}
          handlerFunc={handlerModalInitiate}
          title="Initiate Chat"
          headerModal={true}
          unmountOnClose={true}
          keyboard={true}
          centered={true}
          backdrop="static"
        >
          <Form as="div">
            <Form.Group className="mb-3">
              <Form.Label>Chat ID</Form.Label>
              <p>{detailObjectTabel.chat_id}</p>
            </Form.Group>
            {user.roles_id === 2 ? 
              <Form.Group className="mb-3">
                <Form.Label>
                  Assign to Agent <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select aria-label="Default select example" id='select-agent'>
                  {agent_online.map(agent => (
                    <option key={agent.id} value={agent.id}>{agent.name} ({agent.email})</option>
                  ))}
                </Form.Select>
              </Form.Group> :
              <Form.Group className="mb-3">
                <Form.Label>
                  Assign to me <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select aria-label="Default select example">
                  {/* {agent_online.map(agent => (
                    <option key={agent.id} value={agent.id}>{agent.name} ({agent.email})</option>
                  ))} */}
                  <option key={user.agent_id} value={user.agent_id}>{user.name_agent} ({user.email_agent})</option>
                </Form.Select>
              </Form.Group>
            }
            <Form.Group className="mb-3">
              <Form.Label>
                Message for Client <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                style={{ height: '100px' }}
                placeholder="Type message for client"
                id='messageInitiate'
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-tangerin"
                onClick={handlerInitiateChat}
                disabled={loaderSendHistoryChat ? true : false}
              >
                {loaderSendHistoryChat ? 'Loading...' : 'Initiate Chat'}
              </button>
            </div>
          </Form>
        </ModalMd> 
      </div> 
    </>
    
  );
}


export default ReportChat;
