import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

/* image default */
import { authSelector } from '../../../app/Auth/AuthSlice';
import AvatarDefault from '../../../assets/images/users/avatar/avatar-4.png';
import ModalMd from '../../../Common/Components/Modal/ModalMd';
import ModalSm from '../../../Common/Components/Modal/ModalSm';
import Category from '../../../Common/Components/Tab/TransferChatClient/Category';
import Content from '../../../Common/Components/Tab/TransferChatClient/Content';
import {
  capitalizeFirstMultiParagraph,
  notify,
} from '../../../Common/utils/helpers';
import { solveChat } from '../../../Common/WebSocket/Clients/ClientActions';
import { onlineUsersSelector } from '../Tabs/ChatWithClients/OnlineUsers/OnlineUsersSlice';
import { sendChatHistory } from './DetailChatClientAPI';
import {
  detailChatClientSelector,
  updateStatusRightBar,
} from './DetailChatClientSlice';
import Socket from '../../../Common/WebSocket/Socket';
import { listChatClientSelector } from '../Tabs/ChatWithClients/ListChat/ListChatClientSlice';

function Header(props) {
  const { detailClient, closeChat } = props;
  const avatar = AvatarDefault;
  
  /* state */
  const [dropdownMenuHeader, setDropdownMenuHeader] = useState(false);
  const [transferChatModal, setTransferChatModal] = useState(false);
  const [isModalSendChat, setIsModalSendChat] = useState(false);
  const [transferTabActive, setTransferTabActive] = useState('agent-list');
  const [isConfirmSolve, setIsConfirmSolve] = useState(false);
  const [dataSolve, setDataSolve] = useState({});
  const [emailClient, setEmailClient] = useState('');
  const { department_online, agent_online } = useSelector(onlineUsersSelector);
  const { user } = useSelector(authSelector);
  const [loaderSendHistoryChat, setLoaderSendHistoryChat] = useState(false);
  const { chatId, rightBarMenu, typeConversation } = useSelector(detailChatClientSelector);
  const dispatch = useDispatch();
  const [isTyping, setIsTyping] = useState(false);
  const {transfer} = useSelector(listChatClientSelector);

  let userName = '';
  if (Boolean(detailClient.user_name) && !Boolean(detailClient.agent_name)) {
    userName = capitalizeFirstMultiParagraph(detailClient.user_name);
  }else if(Boolean(detailClient.user_name) && Boolean(detailClient.agent_name) && !Boolean(detailClient.agent_id)){
    userName = capitalizeFirstMultiParagraph(detailClient.agent_name);
  }else if(Boolean(detailClient.user_name) && Boolean(detailClient.agent_name) && Boolean(detailClient.agent_id)){
    userName = capitalizeFirstMultiParagraph(detailClient.user_name);
  }

  /* handler */
  const toggleHandlerHeaderMenu = () =>
    setDropdownMenuHeader(!dropdownMenuHeader);

  const toggleTransferChatModal = () =>
    setTransferChatModal(!transferChatModal);

  const toggleHandlerTabActiveTransfer = (val) => {
    setTransferTabActive(val);
  };

  const toggleHandlerDetailClientMenu = (originStatus) => {
    const status = !Boolean(originStatus) ? true : false;
    dispatch(updateStatusRightBar(status));
  };

  const handlerSolveChat = () => {
    solveChat(dataSolve.chat_id, typeConversation, user.agent_id);
    setIsConfirmSolve(false);
    setDataSolve({});
  };

  const showConfirmationSolveChat = (params) => {
    setIsConfirmSolve(true);
    setDataSolve(params);
  };

  const toggleConfirmationModal = () => {
    setIsConfirmSolve(false);
    setDataSolve({});
  };

  const toggleHandlerModalHistoryChat = () => {
    if (loaderSendHistoryChat) {
      setIsModalSendChat(true);
    } else {
      const status = isModalSendChat ? false : true;
      setIsModalSendChat(status);
    }
  };

  const handlerChangeEmailClient = (event) => {
    setEmailClient(event.target.value);
  };

  const handlerSendHistory = () => {
    if (!emailClient) {
      return notify('error', 3000, `Email client is required!`);
    }

    setLoaderSendHistoryChat(true);

    const data = {
      chat_id: detailClient.chat_id,
      email: emailClient,
    };

    sendChatHistory(data)
      .then((response) => {
        setLoaderSendHistoryChat(false);
        return notify('success', 3000, `Send history chat success!`);
      })
      .catch((err) => {
        setLoaderSendHistoryChat(false);
        return notify('error', 3000, `Send history failed, please try again!`);
      });
  };

  const ElMenuDekstop = (params) => {
    const { status, type } = params;
    const findTransferId = transfer.find(element => element.chat_id === detailClient.chat_id);

    if(type === 'faq'){
      return (
      <li className="list-inline-item">
        <button
          type="button"
          id="solveChat"
          className="btn nav-btn"
          onClick={() => showConfirmationSolveChat(detailClient)}
        >
          <i className="ri-chat-check-fill"></i>
        </button>
        <UncontrolledTooltip target="solveChat" placement="bottom">
          Solve Chat
        </UncontrolledTooltip>
      </li>
      )
    }
    
    if (![9, 10, 11].includes(status)) {
      if (parseInt(detailClient.agent_id) === parseInt(user.agent_id) || findTransferId ) {
        return (
          <>
            <li className="list-inline-item">
              <button
                type="button"
                id="forwardChat"
                className="btn nav-btn"
                onClick={toggleTransferChatModal}
              >
                <i className="ri-chat-forward-fill"></i>
              </button>
              <UncontrolledTooltip target="forwardChat" placement="bottom">
                Transfer Chat
              </UncontrolledTooltip>
            </li>
            <li className="list-inline-item">
              <button
                type="button"
                id="solveChat"
                className="btn nav-btn"
                onClick={() => showConfirmationSolveChat(detailClient)}
              >
                <i className="ri-chat-check-fill"></i>
              </button>
              <UncontrolledTooltip target="solveChat" placement="bottom">
                Solve Chat
              </UncontrolledTooltip>
            </li>
          </>
        );
      } else if(!findTransferId){
        return <></>;
      } else{
        return <></>;
      }
    } else {
      return (
        <li className="list-inline-item">
          <button
            type="button"
            id="forwardChat"
            className="btn nav-btn"
            onClick={toggleHandlerModalHistoryChat}
          >
            <i className="ri-chat-forward-fill"></i>
          </button>
          <UncontrolledTooltip target="forwardChat" placement="bottom">
            Send History Chat
          </UncontrolledTooltip>
        </li>
      );
    }
  };

  const ElMenuMobile = (params) => {
    const { status } = params;

    if (![9, 10, 11].includes(status)) {
      if (parseInt(detailClient.agent_id) === parseInt(user.agent_id)) {
        return (
          <>
            <DropdownItem>
              Transfer{' '}
              <i className="ri-chat-forward-line float-end text-muted"></i>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="/logout">
              Solve Chat{' '}
              <i className="ri-chat-check-line float-end text-muted"></i>
            </DropdownItem>
          </>
        );
      } else {
        return <></>;
      }
    }
  };

  useEffect(() => {
    if (detailClient.user_email) {
      setEmailClient(detailClient.user_email);
    }
  }, [detailClient]);

  useEffect(() => {
    Socket.on("typing",(response)=>{
      if(detailClient.chat_id === response.data.chat_id){
        setIsTyping(true);
        setTimeout(() => {
          setIsTyping(false);
        }, 3000);
      }else{
        setIsTyping(false);
      }      
    });
  }, [])

  return (
    <>
      <div className="p-3 p-lg-4 border-bottom">
        <Row className="align-items-center">
          <Col sm={7} xs={7} className="d-none d-md-block d-lg-block">
            <div className="d-flex align-items-center">
              <div className="me-2 ms-0">
                <button
                  className="btn-user-chat-remove text-muted font-size-16"
                  onClick={(event) => closeChat(event)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
              </div>
              <div className="me-3 ms-0">
                <img
                  src={avatar}
                  className="rounded-circle avatar-xs"
                  alt="avatar"
                />
              </div>
              <div className="flex-grow overflow-hidden">
                <h5 className="font-size-16 mb-0 text-truncate">
                  <Link
                    to="#"
                    className="text-reset user-profile-show"
                    onClick={() => toggleHandlerDetailClientMenu(rightBarMenu)}
                  >
                    {userName}
                  </Link>
                </h5>
                <small className="text-truncate">
                  {isTyping &&
                    <>
                      Typing...
                    </>
                  }
                  
                  {!isTyping &&
                    <>
                    {Boolean(detailClient.user_email)
                      ? detailClient.user_email
                      : '-'}
                    </>
                  }
                  
                </small>
              </div>
            </div>
          </Col>
          <Col xs={5} sm={5} className="d-none d-md-block d-lg-block">
            <ul className="list-inline user-chat-nav text-end mb-0">
              <li className="list-inline-item">
                <button
                  type="button"
                  id="detailClient"
                  className="btn nav-btn"
                  onClick={() => toggleHandlerDetailClientMenu(rightBarMenu)}
                >
                  <i className="ri-user-3-fill"></i>
                </button>
                <UncontrolledTooltip target="detailClient" placement="bottom">
                  Detail Client
                </UncontrolledTooltip>
              </li>
              <ElMenuDekstop status={detailClient.status} type={typeConversation} />
            </ul>
          </Col>
          <Col sm={10} xs={10} className="d-block d-md-none d-lg-none">
            <div className="d-flex align-items-center">
              <div className="me-2 ms-0">
                <button
                  className="btn-user-chat-remove text-muted font-size-16"
                  onClick={(event) => closeChat(event)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
              </div>
              <div className="me-3 ms-0">
                <img
                  src={avatar}
                  className="rounded-circle avatar-xs"
                  alt="avatar"
                />
              </div>
              <div className="flex-grow overflow-hidden">
                <h5 className="font-size-16 mb-0 text-truncate">
                  <Link to="#" className="text-reset user-profile-show">
                    {Boolean(detailClient.user_name)
                      ? detailClient.user_name
                      : '-'}
                  </Link>
                </h5>
                <small className="text-truncate">
                  {Boolean(detailClient.user_email)
                    ? detailClient.user_email
                    : '-'}
                </small>
              </div>
            </div>
          </Col>
          <Col sm={2} xs={2} className="d-block d-md-none d-lg-none">
            <Dropdown
              isOpen={dropdownMenuHeader}
              toggle={toggleHandlerHeaderMenu}
            >
              <DropdownToggle tag="a" className="ms-3">
                <i className="fas fa-ellipsis-v"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem>
                  Info <i className="ri-user-3-line float-end text-muted"></i>
                </DropdownItem>
                <ElMenuMobile status={detailClient.status} />
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <ModalSm
        isOpen={isConfirmSolve}
        handlerFunc={toggleConfirmationModal}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        backdrop={true}
        headerModal={false}
      >
        <div className="mb-3">
          <h5>Solve Chat?</h5>
          <small>
            This message is from client{' '}
            <b>
              <i>{dataSolve.user_name}</i>
            </b>
            , if confirmed it will move to chat history.
          </small>
        </div>
        <div className="text-end mt-2">
          <Button
            color="secondary"
            size="sm"
            className="mx-2"
            outline
            onClick={() => toggleConfirmationModal()}
          >
            Cancel
          </Button>
          <Button
            color="tangerin"
            size="sm"
            outline
            onClick={() => handlerSolveChat()}
          >
            Solve
          </Button>
        </div>
      </ModalSm>
      <ModalMd
        isOpen={transferChatModal}
        handlerFunc={toggleTransferChatModal}
        title="Transfer Chat"
        headerModal={true}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        backdrop="static"
      >
        <Category
          activeTab={transferTabActive}
          handlerClick={toggleHandlerTabActiveTransfer}
        />
        <Content
          sessionUser={user}
          activeTab={transferTabActive}
          dataAgent={agent_online}
          dataDepartment={department_online}
          chatIdActive={chatId}
        />
      </ModalMd>
      <ModalMd
        isOpen={isModalSendChat}
        handlerFunc={toggleHandlerModalHistoryChat}
        title="Send History Chat"
        headerModal={true}
        unmountOnClose={true}
        keyboard={true}
        centered={true}
        backdrop="static"
      >
        <Form as="div">
          <Form.Group className="mb-3" controlId="ChatIDForm">
            <Form.Label>Chat ID</Form.Label>
            <p>{detailClient.chat_id}</p>
          </Form.Group>

          <Form.Group className="mb-3" controlId="EmailForm">
            <Form.Label>
              Email Client <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              onChange={handlerChangeEmailClient}
              type="email"
              value={emailClient}
              placeholder="Type email client"
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-tangerin"
              onClick={handlerSendHistory}
              disabled={loaderSendHistoryChat ? true : false}
            >
              {loaderSendHistoryChat ? 'Loading...' : 'Send'}
            </button>
          </div>
        </Form>
      </ModalMd>
    </>
  );
}

export default Header;
