import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  getChatDetailClient,
  getChatDetailVirtual,
  getDetailHistoryChat,
  getHistoryChatAction,
  updateOpenChat,
} from '../../../features/Client/DetailChat/DetailChatClientSlice';
import { getDetailReportChat, addChatId } from '../../../features/ReportChat/ReportChatSlice';
import {
  capitalizeFirstMultiParagraph,
  parseDateNowVWa,
} from '../../utils/helpers';

/* image default */
import { Badge } from 'reactstrap';
import telegramLogo from '../../../assets/images/social-media/telegram.png';
import whatsappLogo from '../../../assets/images/social-media/wa.png';
import AvatarDefault from '../../../assets/images/users/avatar/avatar-4.png';
import { resetAllStateInputChat } from '../InputChat/InputChatSlice';
import { solveChat } from '../../WebSocket/Clients/ClientActions';
import { authSelector } from '../../../app/Auth/AuthSlice';

function ClientListChat(props) {
  const { data, index, isActive, chatIdActive, detailType, isPending } = props;
  const { user } = useSelector(authSelector);
  let userName = '';
  if (Boolean(data.user_name) && !Boolean(data.agent_name)) {
    userName = capitalizeFirstMultiParagraph(data.user_name);
  }else if(Boolean(data.user_name) && Boolean(data.agent_name) && detailType === 'CP' && isPending){
    userName = capitalizeFirstMultiParagraph(data.agent_name);
  }else{
    userName = capitalizeFirstMultiParagraph(data.user_name);
  }

  let avatar;

  if (data.channel_id === 2) {
    avatar = whatsappLogo;
  } else if (data.channel_id === 3) {
    avatar = telegramLogo;
  } else if (data.channel_id === 4){
    avatar = whatsappLogo;
  }else{
    avatar = AvatarDefault;
  }

  const dateFormatted = parseDateNowVWa(data.formatted_date);
  const date = data.formatted_date;
  const typeConversation = localStorage.getItem('typeConversation')

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isWithinLast3Hours = (dateString) => {
    if (dateString && data.type === 'faq' && isPending) {
        const dateParts = dateString.split(" ");
        const [date, time] = dateParts;
        const [year, month, day] = date.split("-");
        const [hours, minutes, seconds] = time.split(":");

        const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);
        const currentTime = new Date();

        const timeDifferenceInMillis = currentTime - dateObject;
        const timeDifferenceInHours = timeDifferenceInMillis / (1000 * 60 * 60 ); // Convert to hours

        if (timeDifferenceInHours >= 3) {
            solveChat(data.chat_id, 'faq', user.agent_id);
        }

        return timeDifferenceInHours >= 3;
    } else {
        return false;
    }
  };


  const handlerResetAllInputMessage = () => {
    const elmessagePreview = document.getElementById('messagePreview');
    const elmessageDetailChat = document.getElementById('input-message');
    if (elmessageDetailChat) {
      elmessageDetailChat.innerHTML = '';
    }

    if (elmessagePreview) {
      elmessagePreview.innerHTML = '';
    }
  };

  const eventChatDetail = (event, data, detailType) => {
    if (event.altKey) {
      event.preventDefault();
      event.stopPropagation();
    }

    if(props.relatedChat){
      dispatch(addChatId(data.chat_id));
      dispatch(getDetailReportChat(data.chat_id))
      return;
    }

    if (data.chat_id !== chatIdActive) {
      dispatch(updateOpenChat(data));

      navigate(`../chat-with-client/${data.chat_id}-${detailType}`);
      handlerResetAllInputMessage();
      dispatch(resetAllStateInputChat());

      if (detailType === 'CH') {
        dispatch(getDetailHistoryChat(data.chat_id));
        dispatch(getHistoryChatAction(data.chat_id));
      } else if(data.type === 'faq' && detailType === 'CP') {
        dispatch(getChatDetailVirtual(data.chat_id));
      } else if(typeConversation === 'faq' && data.type !== 'general'){
        dispatch(getChatDetailVirtual(data.chat_id));
      }else{
        dispatch(getChatDetailClient(data.chat_id));
      }
    }
  };

  // useEffect(() => {
  //   if(data.type === 'general' && chatIdActive === data.chat_id){
  //     dispatch(getDetailHistoryChat(data.chat_id));
  //   }
  // }, [data.type])

  useEffect(() => {
    // Call the function initially
    isWithinLast3Hours(date);

    // Schedule the function to run every 5 minutes
    const intervalId = setInterval(() => {
        isWithinLast3Hours(date);
    }, 10 * 60 * 1000); // 10 minutes in milliseconds

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    {data.type === 'general' || !isPending ? 
    <>
      <li
        id={`conversation-${data.chat_id}`}
        key={index}
        className={`${isActive}`}
        onClick={(event) => eventChatDetail(event, data, detailType)}
      >
        <Link to="#">
          <div className="d-flex">
            <div className="chat-user-img online2 align-self-center me-3 ms-0">
              <img
                src={avatar}
                className="rounded-circle avatar-xs"
                alt="chatvolution-avatar"
              />
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <div className='d-flex'>
                <h5 className="text-truncate font-size-15 align-self-center">{userName}</h5>
                {data.channel_id === 4 ? 
                <>
                  <h5 
                    className='text-truncate font-size-13 px-1 py-1 ms-2' 
                    style={{ backgroundColor:'#B67352', borderRadius:"8px" }}>
                      IRSFA
                  </h5>
                </>
                : detailType === 'CP' && isPending ? 
                <>
                  <h5 
                    className='text-truncate font-size-13 px-1 py-1 ms-2' 
                    style={{ backgroundColor: data.type === 'faq' ? '#1746A2' : '#628E90', borderRadius:"8px" }}>
                      {data.type === 'faq' ? 'Virtual Assistant' : 'Open'}
                  </h5>
                </>
                : detailType === "CO" && data.first_message === "Initiate Chat" ? 
                <>
                  <h5 
                    className='text-truncate font-size-13 px-1 py-1 ms-2' 
                    style={{ backgroundColor: '#628E90', borderRadius:"8px" }}>
                      Initiate Chat
                  </h5>
                </>
                : <></>}
              </div>
              {!isWithinLast3Hours(date) && data.type === 'faq' && isPending ? (
                    <>
                    <div className='d-flex'>
                      <p className="chat-user-message text-truncate mb-0" style={{ width:"70%" }}>
                        {data.message}
                      </p>
                      <h5 
                        className='text-truncate font-size-13 px-1 py-1 ms-2' 
                        style={{ backgroundColor: '#B04759', borderRadius:"8px" }}>
                          24 Jam
                      </h5>
                    </div>
                    </>
                  ) : (
                    <>
                    <p className="chat-user-message text-truncate mb-0">
                      {data.message}
                    </p>
                    </>
                  )}
              {[9, 10, 11].includes(data.status) && (
                <div className="small py-1">
                  <span className="text-tangerin fa fa-star"></span>{' '}
                  {!data.rating ? 'No Rating' : data.rating}
                </div>
              )}
            </div>
            <div className="d-flex flex-column">
              <div className="font-size-12 mb-2">{dateFormatted}</div>
              {data.unread_count_agent > 0 && (
                <div className="font-size-14 text-end">
                  <Badge color="tangerin" pill>
                    {data.unread_count_agent}
                  </Badge>
                </div>
              )}
            </div>
          </div>
        </Link>
      </li>
    </> : <></>}
    </>
    
    
  );
}

export default ClientListChat;
