import { store } from '../../../app/store';
import { closeChat } from '../../../features/Client/DetailChat/DetailChatClientSlice';
import { updateMessage } from '../../Components/InputChat/InputChatSlice';
import Service from '../../service';
import Socket from '../Socket';
import { addMessage } from '../../../features/Client/DetailChat/DetailChatClientSlice';

export const refreshData = () => {
  Socket.disconnect();
  Socket.connect();
  setTimeout(() => {
    Socket.emit('reload');
  }, 3000);
};

export const reloadOnly = () => {
  Socket.emit('reload');
};

export const joinRoom = (chatId) => {
  Socket.emit('room.join', chatId);
};

export const sendMessage = (chatId, message, file = {}, agent_name, agent_avatar, avatar) => {
  localStorage.setItem(chatId, '');
  let is_sender = true;
  const dataSenderDefault = { chatId, message };
  const additionalData = {agent_name, agent_avatar, avatar, is_sender}
  const resultDataSender = Object.assign(dataSenderDefault, file, additionalData);
  store.dispatch(addMessage(resultDataSender));
  Socket.emit('message', resultDataSender);
  Socket.emit('chat.read.update', {
    chatId: chatId,
  })
  store.dispatch(updateMessage(null));
};

export const solveChat = (chatId, typeConversation, agentId) => {
  Socket.emit('chat.end', { chatId, typeConversation, agentId });
};

export const handlerTransferChat = (chatId, id, type) => {
  const data = {
    chatId,
  };

  if (type === 'user') {
    data.toAgent = id;
  } else {
    data.toDepartment = id;
  }

  Socket.emit('chat.transfer', data);
  store.dispatch(closeChat());
};

export const handlerUpdateLabels = (chatId, labels) => {
  const dataEmit = {
    chatId: chatId,
    labels: labels,
  };
  Socket.emit('label.update', dataEmit);
};

export const handlerLogoutAllSession = () => {
  const state = store.getState();
  const user = state.authSetup.user;

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
      'X-Requested-With': 'xmlhttprequest',
    },
  };

  Service.logoutV1(config)
    .then((response) => {
      Socket.emit('logout');
    })
    .catch((err) => {
      localStorage.clear();
      const urlRedirect = `${process.env.REACT_APP_LIVE_ENDPOINT_V1}/login`;
      window.location.replace(urlRedirect);
    });
};

export const handlerResetChat = (chatId) => {
  Socket.emit('chat.read.reset', {
    chatId: chatId,
    withRefresh: true,
  });
};

export const typingMessage = (chatId,isStatus, message)=>{
  localStorage.setItem(chatId, message)
  Socket.emit("typing",{
    chatId: chatId,
    is_typing: isStatus,
  })
}