import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailChatClientSelector } from '../../../../features/Client/DetailChat/DetailChatClientSlice';
import { sendMessage } from '../../../WebSocket/Clients/ClientActions';
import DivContentEditable from '../../DivContentEditable/DivContentEditable';
import { inputChatSelector, updateMessage } from '../InputChatSlice';
import { typingMessage } from '../../../WebSocket/Clients/ClientActions';
import styles from './Preview.module.css';
import { replyMessage } from '../../../WebSocket/ClientsIrsfa/ClientIrsfaActions';

function InputOnPreview(props) {
  /* configuration */
  const { togglePreviewFileModal } = props;
  const { messageClient, uploadFileResult } = useSelector(inputChatSelector);
  const { chatId, detailClient } = useSelector(detailChatClientSelector);
  const dispatch = useDispatch();
  const setRefCaption = useRef(messageClient);
  const caretPos = useRef({ start: 0, end: 0 });

  /* handler */
  const handlerSendMessage = () => {
    const lengthMsg = messageClient
      .replace(/<\/div>/gi, '')
      .replace(/<br\s*[\/]?>/gi, '')
      .replace(/\s\s+/g, '')
      .replace(/&nbsp;/g, '');

    const dataFile = {
      file_type: uploadFileResult.fileType,
      file_name: uploadFileResult.fileName,
      file_url: uploadFileResult.fileUrl,
      file_path: uploadFileResult.filePath,
      file_id: uploadFileResult.fileId,
    };

    let message = '';
    if (lengthMsg.length > 0) {
      message = messageClient
        .replace(/&nbsp;/g, '')
        .replace(/<br\s*[\/]?>/gi, '\n')
        .trim();
    }

    sendMessage(chatId, message, dataFile);
    if(detailClient.channel_id === 4){
      replyMessage(detailClient.user_phone, message, dataFile);
    }
    togglePreviewFileModal(true);
    dispatch(updateMessage(''));
    const elMsg = document.getElementById('messagePreview');
    elMsg.innerHTML = '';
  };

  const handlerPreviewKeyupMessage = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      handlerSendMessage();
      event.preventDefault();
    }
  };

  const handlerPreviewInputChat = (event) => {
    const element = event.target;
    const message = element.innerText;
    const replaceMsg = message.replace(/\n/g, ' <br> ');
    const selection = window.getSelection();
    caretPos.current = {
      start: selection.anchorOffset,
      end: selection.focusOffset,
    };
    
    typingMessage(chatId,true, message);
    dispatch(updateMessage(replaceMsg));
    if(props.onChange){
      props.onChange(message);
    }
  };

  return (
    <div className="text-center">
      <DivContentEditable
        attrProps={{
          id: 'messagePreview',
          name: 'messagePreview',
          className: `form-control bg-light border-light text-start ${styles.formContenteditable}`,
          placeholder: 'Type caption...',
          contentEditable: true,
        }}
        handlerKey={handlerPreviewKeyupMessage}
        handlerInput={handlerPreviewInputChat}
      />
    </div>
  );
}

export default InputOnPreview;
