import _ from 'lodash';
import React, { useEffect, useState, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { authSelector } from '../../../../app/Auth/AuthSlice';
import { layoutSetupSelector } from '../../../../app/Layouts/LayoutSlice';
import ClientHeaderTab from '../../../../Common/Components/HeaderTab/ClientHeaderTab';
import ClientListChat from '../../../../Common/Components/ListChat/ClientListChat';
import Skeletons from '../../../../Common/Components/Skeletons';
import { filterChatHistory } from '../../../../Common/utils/helpers';
import { detailChatClientSelector } from '../../DetailChat/DetailChatClientSlice';
import {
  getListResolveChat,
  listChatClientSelector
} from '../ChatWithClients/ListChat/ListChatClientSlice';

function ChatHistory() {
  /* config and selector */
  const { history, loader_history, queryListHistory, search_status } = useSelector(
    listChatClientSelector
  );
  const [page, setPage] = useState(1);
  const [chatList, setChatList] = useState([]);
  const [handlingScroll, setHandlingScroll] = useState(false);
  const { user } = useSelector(authSelector);
  const { chatId } = useSelector(detailChatClientSelector);
  const dispatch = useDispatch();
  const { activeTab } = useSelector(layoutSetupSelector);
  const simpleBarRef = useRef();
  const prevHistoryRef = useRef();


  const handlerRefreshListHistory = () => {
    const now = new Date();
    const nowDate = now.toISOString().substring(0, 10);
    const threedaysago = new Date(now.getTime() - (3 * 24 * 60 * 60 * 1000));
    const threedaysagoDate = threedaysago.toISOString().substring(0, 10);
    const data = {
      with_pagination: true,
      company_uuid: user.uuid,
      set_per_page: 10,
      page: 1,
      start_date: threedaysagoDate,
      end_date: nowDate,
    }
    dispatch(getListResolveChat(data));
  };

  // const delayedQuery = useCallback(
  //   _.debounce(() => handlerRefreshListHistory(), 20000),
  //   []
  // );

  // Define a function to handle the scroll event
  const handleScroll = async () => {
    if (handlingScroll) return;
    setHandlingScroll(true);
    const scrollTop = simpleBarRef.current.getScrollElement().scrollTop;
    const scrollHeight = simpleBarRef.current.getScrollElement().scrollHeight;
    const clientHeight = simpleBarRef.current.getScrollElement().clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      // User has scrolled to the bottom, update the page number
      setPage((prevPage) => prevPage + 1);
    }
    setHandlingScroll(false);
  };

  useEffect(() => {
    if (activeTab === 'Chat-w-history') {
      setChatList([]);
      handlerRefreshListHistory();
    }
  }, [activeTab]);

  useEffect(() => {
    const simpleBarElement = simpleBarRef.current.getScrollElement();
    simpleBarElement.addEventListener('scroll', handleScroll);
    return () => {
      simpleBarElement.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, simpleBarRef]);

  useEffect(() => {
    const now = new Date();
    const nowDate = now.toISOString().substring(0, 10);
    const threedaysago = new Date(now.getTime() - (3 * 24 * 60 * 60 * 1000));
    const threedaysagoDate = threedaysago.toISOString().substring(0, 10);
    if(history.length <= 0) return;
    const data = {
      with_pagination: true,
      company_uuid: user.uuid,
      set_per_page: 10,
      page: page,
      start_date: threedaysagoDate,
      end_date: nowDate,
    }
    dispatch(getListResolveChat(data));
  }, [page]);

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
  
    return true;
  }

  useEffect(() => {
    if (history && history.length > 0 && prevHistoryRef.current && !arraysAreEqual(history, prevHistoryRef.current) && !search_status) {
      setChatList((prevChatList) => [...prevChatList, ...history]);
      prevHistoryRef.current = history;
    }

    if(history && history.length > 0 && !prevHistoryRef.current && !search_status){
      setChatList(history);
      prevHistoryRef.current = history;
    }
  }, [history]);

  useEffect(() => {
    const simpleBarElement = simpleBarRef.current.getScrollElement();
    if(loader_history){
      setHandlingScroll(true);
    }else{
      setHandlingScroll(false);
      simpleBarElement.removeEventListener('scroll', handleScroll);
    }
  }, [loader_history])

  useEffect(() => {
    if(search_status){
      setHandlingScroll(true);
    }else{
      setHandlingScroll(false);
    }
  }, [search_status])  

  /* component */
  const { SkeletonListChat } = Skeletons;

  const Items = (params) => {
    const { data, loaderHistory } = params;

    if (loaderHistory) {
      return <SkeletonListChat />;
    }

    const dataListHistory = !queryListHistory
      ? data
      : filterChatHistory(data, queryListHistory);

    if (dataListHistory.length < 1) {
      return (
        <li id="conversation-chat-empty" className="active">
          <div className="d-flex justify-content-center p-5">
            <h5 className="mb-2 font-size-14">Chat Not Found!</h5>
          </div>
        </li>
      );
    }

    return dataListHistory.map((val, index) => {
      let isActive = '';
      if (chatId === val.chat_id) {
        isActive = 'active';
      }

      return (
        <ClientListChat
          key={index}
          index={index}
          data={val}
          isActive={`listChatClient ${isActive}`}
          chatIdActive={chatId}
          detailType="CH"
        />
      );
    });
  };

  return (
    <>
      <div>
        <div className="px-4 pt-3">
          <ClientHeaderTab
            attrClass="mb-3"
            value="Chat History"
            isFieldSearch={true}
            page={page}
          />
        </div>
        <hr className='mt-0' />
        <SimpleBar
          ref={simpleBarRef}
          className="chat-message-list chat-group-list"
        >
          <ul
            className="list-unstyled chat-list chat-user-list px-4"
            id="chat-list"
          >
            {search_status ? <Items loaderHistory={loader_history} data={history} /> : <Items loaderHistory={loader_history} data={chatList} /> }
          </ul>
        </SimpleBar>
      </div>
    </>
  );
}

export default ChatHistory;
